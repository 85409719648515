import { useSelector, useDispatch } from "react-redux";
import {
  getCurrency,
  currencyHasChanged,
} from "../../../reducers/DonationReducer";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const CurrencySelector = (props) => {
  const { id, donationBands } = props;
  const dispatch = useDispatch();
  const currency = useSelector(getCurrency);
  const redButtonOn = useFeatureIsOn("red-donation-button");

  function handleCurrencyChange(e) {
    const newCurrency = e.currentTarget.value;
    dispatch(currencyHasChanged(newCurrency));
  }

  return (
    <select
      id={id}
      data-testid={id}
      className={`w-full border-0 bg-gray-100 py-5 mb-4 font-semibold ${
        redButtonOn ? "focus:border-red-700" : "focus:border-purple-primary"
      } focus:outline-none focus:ring-transparent`}
      value={currency}
      onChange={(e) => handleCurrencyChange(e)}
    >
      {Object.keys(donationBands).map((currency) => {
        return (
          <option key={currency} value={currency} data-testid="select-option">
            {currency}
          </option>
        );
      })}
    </select>
  );
};
export default CurrencySelector;
