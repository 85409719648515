import { useFeatureIsOn } from "@growthbook/growthbook-react";

const CampaignTitle = ({ title }) => {
  const isMobileFormOn = useFeatureIsOn("mobile-form");

  return (
    <h1
      className={`${
        isMobileFormOn ? "" : "mb-3"
      } py-6 bg-mint-primary text-black flex items-center lg:ps-8 ps-3 lg:text-4xl text-2xl priFont-extraBold lg:mb-8`}
    >
      {title}
    </h1>
  );
};

export default CampaignTitle;
