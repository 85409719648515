import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PageContent from "../components/common/PageContent";
import MobileLayout from "../components/Petition/MobileLayout";
import PetitionBar from "../components/Petition/PetitionBar";
import Scroll from "../components/Petition/Scroll";
import "react-loading-skeleton/dist/skeleton.css";
import { setPageDetails } from "../reducers/ActionFormReducer";
import { selectIsMobile } from "../reducers/PageReducer";
import CampaignersPopup from "../components/CampaignersPopup/CampaignersPopup";
import SharePanel from "../components/Internal/SharePanel";
import { useFeatureValue } from "@growthbook/growthbook-react";
import { useRouter } from "next/router";
import analytics from "../lib/analytics";

const Petition = (props) => {
  const {
    countriesList,
    campaign_action_count,
    id,
    language,
    image,
    title,
    slug,
    content,
    sources,
    share_buttons,
    petition,
    fundraiser,
    loading,
    thermometer: { actions: { offset = 0, active = true } } = {},
    publish_status,
    scrollToDonate,
    postActionAsk,
  } = props;

  const dispatch = useDispatch();
  const { events, isReady, query } = useRouter();
  dispatch(setPageDetails({ id, slug, title, petition, fundraiser }));
  const isMobile = useSelector(selectIsMobile);

  const { target, cta, description, form } = petition;

  const { belowLeftSection: showSharePanel } = useFeatureValue(
    "share-panel"
  ) || {
    belowLeftSection: false,
  };

  const petitionBarProps = {
    cta,
    countriesList,
    form,
    description,
    target,
    campaign_action_count,
    id,
    language,
    offset,
    active,
    isMobile,
  };

  const getGa4Traits = () => {
    const akid = query?.akid?.split(".") || [];
    const knownUser = akid.length === 3;
    const source = query?.source || akid[0] ? "mailing" : null;
    const medium = akid[0] ? "email" : null;

    return {
      knownUser,
      campaignName: slug,
      source: source,
      medium: medium,
      ...(knownUser && { userId: akid[1], mailingId: akid[0] }),
    };
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted && isReady) {
      try {
        const traits = getGa4Traits();
        analytics.plugins.customDataPlugin.trackCampaignData({ traits });
        if (traits.knownUser && traits?.userId) {
          analytics.reset(analytics.identify(traits.userId));
        } else {
          analytics.reset();
        }

        analytics.page();
      } catch (error) {
        console.log("An error occurred when trying to send ga traits:", error);
      }
    }

    const handleRouteChange = () => {
      analytics.page();
    };

    events.on("routeChangeComplete", handleRouteChange);
    return () => {
      events.off("routeChangeComplete", handleRouteChange);
      isMounted = false;
    };
  }, [events, isReady]);

  return (
    <>
      {/* Main content */}
      <div className="min-h-screen">
        <div className="max-w-screen-desktop mx-auto">
          <div className="flex lg:flex-row flex-col lg:mb-8">
            <div className="flex flex-col lg:pe-4 w-full xl:w-8/12 lg:w-7/12">
              <PageContent
                imagePath={image?.original.path}
                title={title}
                content={content}
                sources={sources}
                language={language}
                achieved={campaign_action_count + offset}
                active={active}
              ></PageContent>
              {showSharePanel && (
                <SharePanel buttons={share_buttons} slug={slug} />
              )}
              {isMobile && (
                <>
                  <MobileLayout
                    cta={cta}
                    loading={loading}
                    petitionBarProps={petitionBarProps}
                  ></MobileLayout>
                </>
              )}
            </div>
            <div className="lg:w-5/12 xl:w-4/12 w-full">
              {!isMobile && (
                <PetitionBar {...petitionBarProps} loading={loading} />
              )}
            </div>
          </div>
        </div>
        <Scroll
          postActionAsk={postActionAsk}
          scrollToDonate={scrollToDonate}
          shareButtons={share_buttons}
          language={language}
          title={title}
        ></Scroll>
      </div>
      {!isMobile && (
        <CampaignersPopup publish_status={publish_status} lang={language} />
      )}
    </>
  );
};

export default Petition;
