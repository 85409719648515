import { Member } from "@/types/utils/store/client";
import {
  ApplePayDetails,
  ApplePayPayload,
  HostedFieldsTokenizePayload,
  LocalPaymentTokenizePayload,
  PayPalTokenizePayload,
  ThreeDSecureVerifyPayload,
} from "braintree-web";

export type MerchantAccount = {
  USD: string;
  GBP: string;
  EUR: string;
  CHF: string;
  CAD: string;
  AUD: string;
  NZD: string;
  MXN: string;
  ARS: string;
  BRL: string;
};

export const MerchantAccounts: MerchantAccount = {
  USD: "sumofus",
  GBP: "sumofus2_GBP",
  EUR: "sumofus2_EUR",
  CHF: "SumOfUs_CHF",
  CAD: "SumOfUs_CAD",
  AUD: "SumOfUs_AUD",
  NZD: "SumOfUs_NZD",
  MXN: "SumOfUs_MXN",
  ARS: "SumOfUs_ARS",
  BRL: "SumOfUs_BRL",
};

export const supportedLocalPaymentMethods = ["ideal"];

type MerchantCards = {
  [key: string]: string[];
};

type MerchantNetworks = {
  [key: string]: string[];
};

type GoogleEnvironment = {
  [key: string]: Environment;
};

export const MerchantCards: MerchantCards = {
  sumofus: ["visa", "mastercard", "amex", "discover", "jcb"],
  sumofus2_GBP: ["visa", "mastercard", "amex", "maestro", "discover"],
  sumofus2_EUR: ["visa", "mastercard", "amex", "maestro", "discover"],
  SumOfUs_CHF: ["visa", "mastercard", "maestro"],
  SumOfUs_CAD: ["visa", "mastercard", "amex"],
  SumOfUs_AUD: ["visa", "mastercard", "amex"],
  SumOfUs_NZD: ["visa", "mastercard", "amex"],
};

/**
 * Supported networks by merchant
 * @type {MerchantNetworks}
 * @description For GPay use the network values in uppercase
 */
export const SupportedNetworksByMerchant: MerchantNetworks = {
  sumofus: ["visa", "masterCard", "amex", "discover"],
  sumofus2_GBP: ["visa", "mastercard", "amex"],
  sumofus2_EUR: ["visa", "mastercard", "amex"],
  SumOfUs_CHF: ["visa", "mastercard"],
  SumOfUs_CAD: ["visa", "mastercard", "amex"],
  SumOfUs_AUD: ["visa", "mastercard", "amex"],
  SumOfUs_NZD: ["visa", "mastercard", "amex"],
  SumOfUs_MXN: ["visa", "mastercard"],
  SumOfUs_ARS: ["visa", "mastercard"],
  SumOfUs_BRL: ["visa", "mastercard"],
};

// Same as GPay
export type CardNetwork =
  | "AMEX"
  | "DISCOVER"
  | "ELECTRON"
  | "ELO"
  | "ELO_DEBIT"
  | "INTERAC"
  | "JCB"
  | "MAESTRO"
  | "MASTERCARD"
  | "VISA";

// Same as GPay
type Environment = "PRODUCTION" | "TEST";

export const GPayEnvironment: GoogleEnvironment = {
  production: "PRODUCTION",
  development: "TEST",
  preview: "TEST",
};

export interface DonationPayloadBaseProps {
  member: Member;
  amount: number;
  currency: string;
  recurring: boolean;
  vault: boolean;
  queryParameters: string;
  extraParameters?: {};
}

export interface CreditCardPayloadProps extends DonationPayloadBaseProps {
  reCaptchaToken: string;
  reCaptchaAction: string;
  nonce: string;
  deviceData: string;
  threeDSAuthenticationId?: string;
  verifyThreeDSecure?: boolean;
  formLayout?: string;
}

export interface BasePaymentPayloadProps {
  amount: number;
  currency: string;
  recurring: boolean;
  store_in_vault: boolean;
  user: {
    [key: string]: any;
  };
  action_pronto: number;
  source?: string;
  formLayout?: string;
}

export interface GoCardessPayloadProps extends BasePaymentPayloadProps {
  redirect_url: string;
}

export interface LocalPaymentPayloadProps extends BasePaymentPayloadProps {
  pageId: string;
  paymentId: string;
}

export type BraintreeRef = {
  tokenize: (
    frequency: string
  ) => Promise<
    | HostedFieldsTokenizePayload
    | ThreeDSecureVerifyPayload
    | LocalPaymentTokenizePayload
    | ApplePayTokenizePayload
    | PayPalTokenizePayload
  >;
  startPayment: (
    payload: LocalPaymentPayloadProps
  ) => Promise<Promise<LocalPaymentTokenizePayload>>;
  initializing: boolean;
};
export interface PaypalPayloadProps extends CreditCardPayloadProps {}

export interface ExpressPayResponseDetails {
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
  phone?: string;
  consented: boolean;
  postal?: string;
}

export interface ApplePayResponseDetails
  extends ExpressPayResponseDetails,
    ApplePayDetails {}

export interface ApplePayTokenizePayload extends ApplePayPayload {
  details: ApplePayResponseDetails;
}

export interface ExpressPayPayload {
  nonce: string;
  details: ExpressPayResponseDetails;
}

export interface MetaConversionClientPayloadBase {
  email?: string;
  postal?: string;
  country?: string;
  phone?: string;
  userId?: string;
  pageTitle: string;
  pageId?: number;
  currency?: string;
  amount?: number;
  pathname: string;
  action: string;
  fbc: string;
  fbp: string;
  eventId: string;
}

export interface MetaConversionClientPayload
  extends MetaConversionClientPayloadBase {
  ip: string;
  userAgent: string;
}
