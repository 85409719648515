import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslations } from "next-intl";
import { getCountry } from "../../reducers/ActionFormReducer";
import { getCurrency } from "../../reducers/DonationReducer";
import { getPageSlug, getPageType } from "../../reducers/PageReducer";
import CurrencyAmount from "../Donation/Helpers/CurrrencyAmount";
import {
  getCountry as getCurrencyForCountry,
  formatCurrency,
} from "country-currency-map";
import analytics from "../../lib/analytics";
import { GA4_BUTTON_CLICK } from "../TrackingScripts/helpers";
import classNames from "classnames";
import { currencySymbols } from "../../utils/constants";

const GuardianFundraiserPopup = () => {
  const donationBands = {
    oneTime: [5, 7],
    monthly: [5, 7],
  };

  const t = useTranslations("default");
  const supportedCurrencies = Object.keys(currencySymbols);

  const country = useSelector(getCountry);
  const currencyFromGeoLocation = useSelector(getCurrency);
  const { currency = currencyFromGeoLocation } =
    getCurrencyForCountry(country?.label) || {};
  const [selectedAmount, setSelectedAmount] = useState(
    donationBands.oneTime[1]
  );
  const slug = useSelector(getPageSlug);
  const pageType = useSelector(getPageType);

  const [selectedFrequency, setSelectedFrequency] = useState("oneTime");
  const [close, setClose] = useState(false);
  const [customAmount, setCustomAmount] = useState("");
  const [ready, setReady] = useState(false);

  const handleClose = () => {
    analytics.track(GA4_BUTTON_CLICK, {
      button_id: "close-fr-popup",
      button_text: t("fr_popup_close"),
      action_page_type: pageType,
      slug,
    });
    setClose(true);
  };

  const handleDonate = (e) => {
    e.preventDefault();
    const isSupportedCurrency = supportedCurrencies[currency] !== undefined;
    const selectedCurrency =
      currency != null && isSupportedCurrency ? currency : "USD";
    const url = `${t(
      "donate_page_url"
    )}?currency=${selectedCurrency}&amount=${selectedAmount}&source=frpopup&recurring_default=${
      selectedFrequency === "oneTime" ? "only_one_off" : "only_recurring"
    }`;
    analytics.track(GA4_BUTTON_CLICK, {
      button_id: "donate-fr-popup",
      button_text: t("button_donate_now"),
      action_page_type: pageType,
      slug,
    });
    window.open(url, "_blank");
  };

  const handleAmountChange = (e) => {
    e.preventDefault();
    const amount = e.target.value.replace(/\D/g, "");
    analytics.track(GA4_BUTTON_CLICK, {
      button_id: "setAmount-fr-popup",
      button_text: amount,
      action_page_type: pageType,
      slug,
    });
    setSelectedAmount(amount);
  };

  const handleCustomAmountChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    // Check if the value is a valid number
    if (!isNaN(value) && value.trim() !== "") {
      if (currency && supportedCurrencies[currency] !== undefined) {
        setCustomAmount(formatCurrency(value, currency));
      } else {
        setCustomAmount(value);
      }
      handleAmountChange(e);
    } else {
      setCustomAmount("");
      setSelectedAmount(donationBands[selectedFrequency][1]);
    }
  };

  const handleDonationFrequencyChange = (e, frequency) => {
    e.preventDefault();
    analytics.track(GA4_BUTTON_CLICK, {
      button_id: "setFrequency-fr-popup",
      button_text: frequency,
      action_page_type: pageType,
      slug,
    });
    setSelectedFrequency(frequency);
    if (donationBands[frequency].indexOf(selectedAmount) === -1)
      setSelectedAmount(donationBands[frequency][1]);
  };

  const sentences = t("fr_popup_message").split(".");
  const secondToLastIndex = sentences.length - 2;
  const lastSentence = sentences.length - 1;

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !ready) {
      setTimeout(() => {
        setReady(true);
      }, 2000);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {ready && (
        <aside
          id="ugly-fr-popup"
          data-testid="ugly-fr-popup"
          className={`overflow-y-auto fixed w-full p-6 h-auto lg:min-h-360 max-h-screen-100 lg:max-h-96 bg-purple-guardian dark:bg-gray-800 border-t border-gray-300 border-3 p-8 ${
            close ? "hidden" : ""
          }`}
        >
          <div className="container mx-auto grid grid-cols-12 gap-x-6 gap-y-3 items-center text-purple-guardianText">
            <div className="col-span-12  pl-0 order-1 sm:order-none lg:col-span-8">
              <h2 className="text-3xl priFont-extraBold px-4">
                {t("fr_popup_title")}
              </h2>
            </div>
            <div className="col-span-12 pl-0 order-3 sm:order-none lg:col-span-8">
              <p className="text-md mt-3 text-black p-4">
                {sentences.map((sentence, index) => (
                  <span
                    key={index}
                    className={classNames("pe-1", {
                      "mb-4 bg-purple-guardianText bg-opacity-75 px-1 rounded-md text-white":
                        index === secondToLastIndex,
                      "ps-1 priFont-extraBold": lastSentence === index,
                    })}
                  >
                    {sentence.trimStart() +
                      (index < sentences.length - 1 ? "." : "")}
                  </span>
                ))}
              </p>
            </div>
            <div className="col-span-12  lg:col-span-4 order-1 sm:order-none">
              <form className="flex flex-col space-y-4">
                <div className="flex flex-col space-y-2">
                  <div className="flex space-x-2">
                    <label
                      className={`cursor-pointer inline-flex items-center justify-center whitespace-nowrap text-sm priFont-semiBold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-10 px-4 py-2 w-full bg-white text-black hover:bg-blue-guardianLighter focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white ${
                        selectedFrequency === "oneTime"
                          ? "bg-blue-guardianLight text-blue-guardian border-blue-guardian border-solid border-2"
                          : "border border-input "
                      }`}
                    >
                      <input
                        type="radio"
                        name="frequency"
                        value="oneTime"
                        className="sr-only"
                        onChange={(e) =>
                          handleDonationFrequencyChange(e, "oneTime")
                        }
                      />
                      {t("one_time")}
                    </label>
                    <label
                      className={`cursor-pointer inline-flex items-center justify-center whitespace-nowrap text-sm priFont-semiBold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-10 px-4 py-2 w-full bg-white text-black hover:bg-blue-guardianLighter focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white ${
                        selectedFrequency === "monthly"
                          ? "bg-blue-guardianLight text-blue-guardian border-blue-guardian border-solid border-2"
                          : "border border-input "
                      }`}
                    >
                      <input
                        type="radio"
                        name="frequency"
                        value="monthly"
                        className="sr-only"
                        onChange={(e) =>
                          handleDonationFrequencyChange(e, "monthly")
                        }
                      />
                      {t("monthly")}
                    </label>
                  </div>
                </div>
                <div className="flex flex-col space-y-2">
                  <div className="flex space-x-2">
                    {donationBands[selectedFrequency].map((amount, index) => {
                      return (
                        <label
                          key={index}
                          className={`cursor-pointer inline-flex items-center justify-center whitespace-nowrap text-sm priFont-semiBold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-10 px-4 py-2 w-full bg-white text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white hover:bg-blue-guardianLighter ${
                            selectedAmount == amount
                              ? "bg-blue-guardianLight text-blue-guardian border-blue-guardian border-solid border-2"
                              : "border border-input "
                          }`}
                        >
                          <input
                            key={index}
                            type="radio"
                            name="amount"
                            value={amount}
                            className="sr-only"
                            onChange={(e) => handleAmountChange(e)}
                            checked={amount == selectedAmount}
                          />
                          <CurrencyAmount currency={currency} amount={amount} />
                        </label>
                      );
                    })}
                    <input
                      className="flex h-10 w-full border border-input px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:priFont-semiBold placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 bg-white text-blue-guardian dark:bg-gray-800 dark:text-purple-guardianText focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                      placeholder={t("other")}
                      onChange={(e) => handleCustomAmountChange(e)}
                      value={customAmount}
                    />
                  </div>
                </div>
                <div className="flex justify-between mt-4">
                  <button
                    className="cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-full text-sm priFont-semiBold h-10 px-2 py-1 w-1/2 bg-purple-guardianText text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    onClick={(e) => handleDonate(e)}
                  >
                    {t("button_donate_now")}
                    <i className="fas fa-arrow-right fa-2xl m-4"></i>
                  </button>
                </div>
              </form>
            </div>
            <button
              className="cursor-pointer absolute top-3 right-3 p-2 border rounded-full flex items-center justify-center w-10 h-10"
              style={{ color: "#052962", borderColor: "#052962" }}
              onClick={handleClose}
            >
              X
            </button>
          </div>
        </aside>
      )}
    </>
  );
};

export default GuardianFundraiserPopup;
