import { useEffect } from "react";
import { useTranslations } from "next-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  validateForm,
  getFormErrors,
  getForm,
  setFormValues,
  getFormValues,
  getFormSubmitting,
  setFormSubmitting,
  setPaymentMethods,
  getCurrency,
  getDonationAmount,
  setGaItem,
  getGaItem,
} from "../../../reducers/DonationReducer";
import { getMember } from "../../../reducers/MemberReducer";
import { getPageSlug, getPageType } from "../../../reducers/PageReducer";
import CountryList from "../../Form/Fields/CountryList";
import { InputField } from "../../Form/Fields/InputField";
import Button from "../../Internal/Button";
import analytics from "../../../lib/analytics";
import {
  GA4_INITIATE_CHECKOUT,
  GA4_SHIPPING_INFO,
  GA4_ADD_ITEM_TO_CART,
} from "../../TrackingScripts/helpers";
import { useRouter } from "next/router";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const MemberDetails = (props) => {
  const { ctaText } = props;

  const t = useTranslations("default");
  const router = useRouter();

  const dispatch = useDispatch();
  const redButtonOn = useFeatureIsOn("red-donation-button");

  const formFields = useSelector(getForm);
  const formValues = useSelector(getFormValues);
  const formErrors = useSelector(getFormErrors);
  const currency = useSelector(getCurrency);
  const amount = useSelector(getDonationAmount);
  const member = useSelector(getMember);
  const formSubmitting = useSelector(getFormSubmitting);
  const gaItem = useSelector(getGaItem);
  const slug = useSelector(getPageSlug);
  const pageType = useSelector(getPageType);

  function getCTAtext() {
    if (formSubmitting) {
      return t("processing");
    } else if (ctaText) {
      return ctaText;
    } else {
      return t("submit");
    }
  }

  const ga4Tracking = () => {
    const query = router.query;
    if (query && query.currency && query.amount) {
      const listName = "amount from mailing";
      const itemId = "custom-amount-from-mailing";
      const price = parseFloat(amount).toFixed(2);
      const payload = {
        currency,
        value: price,
        slug,
        action_page_type: pageType,
        items: [
          {
            item_id: itemId,
            item_name: itemId,
            item_list_name: listName,
            price,
            quantity: 1,
          },
        ],
      };
      analytics.track(GA4_ADD_ITEM_TO_CART, payload);
      analytics.track(GA4_INITIATE_CHECKOUT, payload);
      dispatch(setGaItem({ id: itemId, listName }));
    }
  };

  function fieldsToDisplay() {
    return formFields.filter((field) => {
      switch (field.display_mode) {
        case "all_members":
          return true;
        case "recognized_members_only":
          return recognizedMemberPresent();
        case "new_members_only":
          return !recognizedMemberPresent();
        default:
          console.log(
            `Unknown display_mode "${field.display_mode}" for field "${field.name}"`
          );
          return false;
      }
    });
  }

  function recognizedMemberPresent() {
    return !!member.email;
  }

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const listName = gaItem?.listName;
    const itemId = gaItem?.id;
    const price = parseFloat(amount).toFixed(2);
    dispatch(setFormSubmitting(true));
    try {
      dispatch(validateForm());
      dispatch(setPaymentMethods());
      analytics.track(GA4_SHIPPING_INFO, {
        currency: currency,
        value: price,
        slug,
        action_page_type: pageType,
        shipping_tier: "Ground",
        items: [
          {
            item_id: itemId,
            item_name: itemId,
            item_list_name: listName,
            price,
            quantity: 1,
          },
        ],
      });
    } catch (e) {
      console.log("FAILED", e);
      dispatch(setFormSubmitting(false));
    }
  };

  const onInputFieldChange = (e) => {
    dispatch(setFormValues(e));
  };

  useEffect(() => {
    ga4Tracking();
  }, []);

  return (
    <div className="py-8 flex flex-col w-full h-full justify-between MemberDetailsForm-root">
      <form onSubmit={handleSubmitForm} className="action-form">
        {fieldsToDisplay().map(
          ({ id, data_type, name, label, value, required }) => {
            switch (data_type) {
              case "text":
                return (
                  <InputField
                    id={id}
                    key={name}
                    name={name}
                    placeholder={label}
                    required={required}
                    type={data_type}
                    value={formValues[name]}
                    onChange={onInputFieldChange}
                    errorMsg={
                      formErrors && formErrors[name] && formErrors[name][0]
                        ? formErrors[name][0]
                        : null
                    }
                  />
                );
              case "email":
                return (
                  <InputField
                    id={id}
                    key={name}
                    name={name}
                    placeholder={label}
                    required={required}
                    type={data_type}
                    value={formValues[name]}
                    onChange={onInputFieldChange}
                    errorMsg={
                      formErrors && formErrors[name] && formErrors[name][0]
                        ? formErrors[name][0]
                        : null
                    }
                    pattern={
                      "^[a-zA-Z0-9._%+\\-]+@[a-zA-Z0-9.\\-]+\\.[a-zA-Z]{2,}$"
                    }
                    title="Enter a valid email - username@domain.com"
                  />
                );
              case "postal":
                return (
                  <InputField
                    id={id}
                    key={name}
                    name={name}
                    placeholder={label}
                    required={required}
                    type={"text"}
                    maxLength={249}
                    value={formValues[name]}
                    onChange={onInputFieldChange}
                    errorMsg={
                      formErrors && formErrors[name] && formErrors[name][0]
                        ? formErrors[name][0]
                        : null
                    }
                  />
                );
              case "phone":
                return (
                  <InputField
                    id={id}
                    key={name}
                    name={name}
                    placeholder={label}
                    required={required}
                    type={"tel"}
                    maxLength={249}
                    value={formValues[name]}
                    onChange={onInputFieldChange}
                    errorMsg={
                      formErrors && formErrors[name] && formErrors[name][0]
                        ? formErrors[name][0]
                        : null
                    }
                  />
                );
              case "country":
                return (
                  <CountryList
                    id={id}
                    key={name}
                    label={label}
                    errorMsg={
                      formErrors && formErrors[name] && formErrors[name][0]
                        ? formErrors[name][0]
                        : null
                    }
                  />
                );
              default:
                console.log("no match", data_type);
            }
          }
        )}
        <Button
          disabled={formSubmitting}
          type="submit"
          classes="MemberDetails__proceed-button"
          dataTestId="proceed-to-payment"
          backgroundColor={redButtonOn ? "bg-red-700" : "bg-purple-primary"}
        >
          <span className="text-sm lg:text-lg priFont-extraBold">
            {getCTAtext()}
          </span>
        </Button>
      </form>
    </div>
  );
};

export default MemberDetails;
