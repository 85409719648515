import { useSelector } from "react-redux";
import CurrencyAmount from "../Helpers/CurrrencyAmount";
import {
  getCurrency,
  getDonationAmount,
} from "../../../reducers/DonationReducer";
import { useTranslations } from "next-intl";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const FinePrintMsg = ({ weeklyPrint }) => {
  const t = useTranslations("default");
  const redButtonOn = useFeatureIsOn("red-donation-button");

  return (
    <div className="mt-4">
      <p className="text-xs text-gray-500 pt-2">
        <span>
          {t.rich("fine_print", {
            a: (children) => (
              <a
                href={t("privacy_url")}
                target="_blank"
                rel="noopener noreferrer"
                className={`${
                  redButtonOn ? "text-red-700" : "text-purple-primary"
                }`}
              >
                {children}
              </a>
            ),
          })}
        </span>
      </p>
      <p className="text-xs text-gray-500 pt-2">
        <span>
          {t.rich("recaptcha_tagline", {
            a: (children) => (
              <a
                href={t("recaptcha_privacyLink")}
                target="_blank"
                rel="noopener noreferrer"
                className={`${
                  redButtonOn ? "text-red-700" : "text-purple-primary"
                }`}
              >
                {children}
              </a>
            ),
            a: (children) => (
              <a
                href={t("recaptcha_termsLink")}
                target="_blank"
                rel="noopener noreferrer"
                className={`${
                  redButtonOn ? "text-red-700" : "text-purple-primary"
                }`}
              >
                {children}
              </a>
            ),
          })}
        </span>
      </p>
    </div>
  );
};

const CompleteDonationMsg = () => {
  //  Hide the payment message component as part of montly sustainers project suggested by FR team.
  return null;
  // Refer prosecco PR - #423

  const t = useTranslations("default");

  const amount = useSelector(getDonationAmount);
  const currency = useSelector(getCurrency);

  return (
    <p className="text-center text-lg priFont-extraBold">
      {t("complete_donation", {
        amount: CurrencyAmount({
          amount,
          currency,
        }),
      })}
    </p>
  );
};

const AdditionalPrintMsg = ({ currentPaymentType }) => {
  const t = useTranslations("default");

  return (
    <>
      {currentPaymentType === "paypal" && (
        <p className="text-xs italic py-2">{t("ready_for_paypal")}</p>
      )}
      {currentPaymentType === "gocardless" && (
        <p className="text-xs italic py-2">{t("ready_for_gocardless")}</p>
      )}
    </>
  );
};

export { FinePrintMsg, CompleteDonationMsg, AdditionalPrintMsg };
