import React, { useState, useEffect } from "react";
import Image from "next/image";
import axios from "axios";
import { formatDistanceToNow } from "date-fns";
import { enGB, fr, es, pt, nl, de, ar } from "date-fns/locale";

import {
  getAkPageIds,
  getPageType,
  selectLanguage,
} from "../../../reducers/PageReducer";
import { useSelector } from "react-redux";
import { Signature } from "@/types/components/signature-ticker";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const URI = process.env.NEXT_PUBLIC_LATEST_SIGNATURES_API;

const languages = {
  en: enGB,
  fr,
  es,
  pt,
  nl,
  de,
  ar,
};

const SignatureTicker = () => {
  const showSignatures = useFeatureIsOn("signature-ticker");

  const [signatures, setSignatures] = useState<Signature[]>([]);
  const [currentSignatureIndex, setCurrentSignatureIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const akPageIds = useSelector(getAkPageIds);
  const pageType = useSelector(getPageType);
  const language = useSelector(selectLanguage);
  const locale = languages[language];
  const pageId = akPageIds[pageType];

  useEffect(() => {
    const fetchSignatures = async () => {
      try {
        const uri = `${URI}?page_id=${pageId}`;
        const response = await axios.get(`${URI}?page_id=${pageId}`);
        const data = response.data as Signature[];
        setSignatures(data);
      } catch (error) {
        console.error("Error fetching signatures:", error);
      }
    };

    fetchSignatures();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentSignatureIndex(
          (prevIndex) => (prevIndex + 1) % signatures.length
        );
        setFade(true);
      }, 500); // Duration of fade-out effect
    }, 5000); // Change signature every 5 seconds

    return () => clearInterval(interval);
  }, [signatures.length]);

  const signature = signatures[currentSignatureIndex];

  if (!signature) return null;

  const { created_at, name, country } = signature;

  return (
    showSignatures && (
      <div className="ticker ml-2 mb-0 md:mb-5 md:ml-0">
        {name && (
          <div
            className={`ticker-item flex items-center transition-opacity duration-500 ${
              fade ? "opacity-100" : "opacity-0"
            }`}
          >
            <Image
              src={`/flags/${country.toLowerCase()}.png`}
              alt={`${country} flag`}
              width={24}
              height={16}
              className="flag-icon mr-2"
            />
            <span>
              {name}{" "}
              {formatDistanceToNow(new Date(created_at), {
                addSuffix: true,
                locale: locale,
              })}
            </span>
          </div>
        )}
      </div>
    )
  );
};

export default SignatureTicker;
