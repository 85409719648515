import React, { useEffect, useState, useRef } from "react";
import SharePanel from "../Internal/SharePanel";
import { useSelector } from "react-redux";
import { getPageSlug } from "../../reducers/PageReducer";
import { useFeatureValue } from "@growthbook/growthbook-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useTranslations } from "next-intl";

const ShareComponent = ({ buttons }) => {
  const slug = useSelector(getPageSlug);
  const t = useTranslations("default");

  const secondsOfInactivity = useFeatureValue("inactivity-timer") || 10;

  const [isInactive, setIsInactive] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [isFundraiserInView, setIsFundraiserInView] = useState(false);
  const inactivityTimer = useRef(null);

  useEffect(() => {
    const fundraiserDiv = document.getElementById("fundraiser-view");

    const resetInactivityTimer = (event) => {
      const clickedElement = event?.target;
      const openSharePopupElement = document.getElementById("open-share-popup");

      if (
        event &&
        clickedElement &&
        openSharePopupElement &&
        openSharePopupElement.contains(clickedElement)
      ) {
        return;
      }

      setIsInactive(false);
      clearTimeout(inactivityTimer.current);
      inactivityTimer.current = setTimeout(() => {
        setIsInactive(true);
      }, secondsOfInactivity * 1000);
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsFundraiserInView(entry.isIntersecting);
      },
      { threshold: 0.8 }
    );

    if (fundraiserDiv) {
      observer.observe(fundraiserDiv);
    }

    window.addEventListener("click", resetInactivityTimer);
    window.addEventListener("keypress", resetInactivityTimer);
    window.addEventListener("keydown", resetInactivityTimer);
    resetInactivityTimer();

    return () => {
      window.removeEventListener("click", resetInactivityTimer);
      window.removeEventListener("keypress", resetInactivityTimer);
      window.removeEventListener("keydown", resetInactivityTimer);
      clearTimeout(inactivityTimer.current);
      if (fundraiserDiv) {
        observer.unobserve(fundraiserDiv);
      }
    };
  }, [isFundraiserInView]);

  return (
    <div className="w-full" id="share-component-floater">
      {isInactive && isFundraiserInView && (
        <div className="fixed bottom-0 inset-x-0 z-50 bg-purple-300 py-4 border border-solid rounded-lg animate-fadeIn">
          <div className="px-4 flex justify-between items-center font-bold">
            <div>{t("wish_to_share")}</div>
            <div
              id="open-share-popup"
              className="bg-white p-1.5 border border-solid rounded-full shadow-lg font-bold text-md"
              onClick={() => setOpenPopup(!openPopup)}
            >
              <FontAwesomeIcon
                icon={openPopup ? faAngleDown : faAngleUp}
                size="sm"
                fixedWidth
                className=" self-center"
              />
            </div>
          </div>

          {openPopup && <SharePanel buttons={buttons} slug={slug} />}
        </div>
      )}
    </div>
  );
};

export default ShareComponent;
