import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import Thermometer from "../../Internal/Thermometer";
import Form from "../../Form";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslations } from "next-intl";

import { postAction, trackConversions } from "../../../api";
import { batchActions } from "../../../lib/redux-utils";
import analytics from "../../../lib/analytics";
import {
  GA4_FORM_SUBMIT,
  FB_EV_LEAD,
  FB_EV_COMPLETE_REGISTRATION,
} from "../../TrackingScripts/helpers";
import { logEventToFacebook } from "../../TrackingScripts/LogPixel";
import {
  getSubmitting,
  getCtaCompleted,
  selectErrors,
  selectFormData,
  SET_SUBMITTING,
  SET_ERRORS,
  SET_SHOW_DOUBLE_OPT_IN_BANNER,
  SET_CTA_COMPLETED,
} from "../../../reducers/ActionFormReducer";
import {
  selectIsConsentValid,
  SET_CONSENT_ERROR,
  getShowConsentAsk,
  getConsentValue,
} from "../../../reducers/ConsentReducer";
import {
  setMember,
  getIsMemberPresent,
  getMember,
} from "../../../reducers/MemberReducer";
import {
  getPageId,
  getPageSlug,
  getPageTitle,
  getPageType,
} from "../../../reducers/PageReducer";
import { seedFormValues } from "../../../reducers/DonationReducer";
import { handleException } from "../../../lib/handleApiErrorRespose";
import { getAcceptAll } from "../../../reducers/CookiePreferenceReducer";
import { validatePostalCode } from "../../../lib/utils/postalCodeValidator";
import { isValidPhone } from "../../../lib/utils/phoneValidator";
import { useFeatureValue, useFeatureIsOn } from "@growthbook/growthbook-react";
import { hashData } from "../../../lib/cryptoUtils";
import { getEnhancedConversionsData } from "../../TrackingScripts/LogGA";

function PetitionBar({
  member,
  form,
  cta,
  description,
  target,
  countriesList,
  campaign_action_count,
  id,
  isMobile,
  openModal,
  language,
  loading,
  offset,
  active,
  ...rest
}) {
  const dispatch = useDispatch();
  const t = useTranslations("default");
  const ctaRef = useRef(null);
  const { query, isReady, push, prefetch } = useRouter();

  const [errObj, setErrObj] = useState(null);
  const isSubmitting = useSelector(getSubmitting);
  const ctaCompleted = useSelector(getCtaCompleted);
  const errors = useSelector(selectErrors);
  const formData = useSelector(selectFormData);
  const consentIsValid = useSelector(selectIsConsentValid);
  const consentValue = useSelector(getConsentValue);
  const isMemberPresent = useSelector(getIsMemberPresent);
  const pageId = useSelector(getPageId);
  const pageSlug = useSelector(getPageSlug);
  const pageType = useSelector(getPageType);
  const pageTitle = useSelector(getPageTitle);
  const showConsentAsk = useSelector(getShowConsentAsk);
  const isTrackingCookieAccepted = useSelector(getAcceptAll);
  const savedMemberDetails = useSelector(getMember);
  const redirectToCarouselPage = useFeatureIsOn("carousel");
  const { redirectToNewForms, layout } = useFeatureValue(
    "new-forms-post-action",
    { redirectToNewForms: false, layout: "control" }
  );

  const { scrollToNewForm } = useFeatureValue("scroll-to-new-form", {
    scrollToNewForm: false,
  });

  const { irclickid } = query;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptchaAction = `petition/pronto/${pageId}`;

  const handleGATracking = () => {
    try {
      const { consent, pageId, email, action_phone_number } = formData || {};

      analytics.track(GA4_FORM_SUBMIT, {
        form_id: "action-form",
        form_name: "action-form",
        form_destination: `https://actions.eko.org/api/pages/${pageId}/actions`,
        consent: consent || true,
        form_submit_text: cta || t("sign_it"),
        slug: pageSlug,
        action_page_type: pageType,
        enhanced_conversion_data: getEnhancedConversionsData({
          email,
          phone: action_phone_number,
        }),
      });
    } catch (error) {
      console.error("Error tracking the action", JSON.stringify(error));
    }
  };

  const handleFaceBookTracking = async (payload) => {
    const { email, postal, country, action_phone_number, phone } = payload;
    const clientPayload = {
      email,
      postal,
      country,
      user_id: payload.userId,
      phone: phone || action_phone_number,
    };
    try {
      // If its a new member trigger CompleteRegistration
      // we get the user id in response
      if (payload.userId) {
        logEventToFacebook(FB_EV_COMPLETE_REGISTRATION, {
          ...clientPayload,
          content_name: pageTitle,
          page_id: pageId,
          status: true,
        });
        await trackConversions(
          {
            action: FB_EV_COMPLETE_REGISTRATION,
            payload: {
              ...clientPayload,
              pageId,
              pathname: `/a/${pageSlug}`,
              pageTitle,
            },
          },
          isTrackingCookieAccepted
        );
      }
      // If its a new member from GDPR country & have consented NO trigger LEAD
      else if (!isMemberPresent && consentValue === false) {
        logEventToFacebook(FB_EV_LEAD, {
          content_name: pageTitle,
          pageId,
        });
        await trackConversions(
          {
            action: FB_EV_LEAD,
            payload: clientPayload,
          },
          isTrackingCookieAccepted
        );
      }
    } catch (error) {
      return handleException(error);
    }
  };

  const handleActionTracking = (payload) => {
    handleGATracking();
    handleFaceBookTracking(payload);
  };

  async function getRecaptchaToken() {
    if (!executeRecaptcha) {
      console.info("Execute recaptcha not yet available");
      return "";
    } else {
      const recaptcha_token = await executeRecaptcha(recaptchaAction);
      return recaptcha_token;
    }
  }

  const handleSubmitForm = async (event) => {
    event.preventDefault();

    const reCaptchaToken = await getRecaptchaToken();

    if (consentIsValid) {
      dispatch({ type: SET_SUBMITTING, payload: true });
      const { pageId, action_phone_number, postal, phone, ...rest } = formData;
      const basePayload = {
        reCaptchaToken,
        ...rest,
        irclickid,
        ...(phone && isValidPhone(phone) && { phone }),
        ...(action_phone_number &&
          isValidPhone(action_phone_number) && {
            action_phone_number,
          }),
        ...(postal &&
          validatePostalCode(postal, rest.country?.value) && { postal }),
        ...(consentValue !== null && { consented: consentValue }),
      };

      const payload = isMemberPresent
        ? {
            ...basePayload,
            ...savedMemberDetails,
            consented: basePayload.consented,
          }
        : basePayload;

      try {
        const actionResponse = await postAction(pageId, payload);
        const {
          follow_up_url: followUpUrl = null,
          errors = [],
          double_opt_in: doubleOptIn = false,
          tracking: { user_id: userId } = { user_id: null },
        } = actionResponse;

        if (!followUpUrl) {
          if (errors.length) {
            dispatch({ type: SET_ERRORS, payload: errors });
          } else {
            console.warn(
              "Error submitting the signature and there aren't any errors in the response. Please check the functionality"
            );
          }
          dispatch({ type: SET_SUBMITTING, payload: false });
        } else {
          if (isMobile) {
            openModal(false);
          }

          try {
            handleActionTracking({ ...payload, userId });
          } catch (error) {
            console.error("Error tracking the action", JSON.stringify(error));
          }

          const actions = [];
          if (doubleOptIn) {
            actions.push({
              type: SET_SHOW_DOUBLE_OPT_IN_BANNER,
              payload: true,
            });
          }

          const {
            name,
            email,
            postal,
            action_phone_number,
            country,
            consented,
          } = payload;
          const member = {
            name,
            email,
            postal,
            action_phone_number,
            country,
            consented,
          };

          if (!showConsentAsk || (showConsentAsk && consentValue === true)) {
            actions.push(seedFormValues({ ...member }));
            actions.push(setMember({ ...member }));
          }

          if (redirectToCarouselPage) {
            if (!showConsentAsk || (showConsentAsk && consentValue === true)) {
              // saving the payload in session storage
              sessionStorage.setItem("carousel", JSON.stringify(member));
            }
          }

          if (scrollToNewForm)
            sessionStorage.setItem("memberDetails", JSON.stringify(member));

          if (redirectToNewForms) {
            console.log("Redirecting to new form page");
            push(`/${layout}/${pageSlug}${window?.location?.search}`);
          } else {
            actions.push({ type: SET_CTA_COMPLETED, payload: true });
            actions.push({ type: SET_SUBMITTING, payload: false });
          }

          batchActions(dispatch, actions);
        }
      } catch (error) {
        console.error("Error submitting the signature", JSON.stringify(error));
        dispatch({ type: SET_SUBMITTING, payload: false });
      }
    } else {
      dispatch({ type: SET_CONSENT_ERROR, payload: true });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && errors.length) {
      setErrObj(errors);
    }

    return () => {
      isMounted = false;
    };
  }, [errors]);

  useEffect(() => {
    let isMounted = true;
    const { one_c_sign } = query;
    if (
      isMounted &&
      isReady &&
      one_c_sign === "true" &&
      ctaRef &&
      ctaRef.current
    ) {
      ctaRef.current.click();
    }

    return () => {
      isMounted = false;
    };
  }, [query, isReady, ctaRef]);

  useEffect(() => {
    try {
      if (redirectToNewForms) {
        console.log("Prefetching new form page");
        prefetch(`/${layout}/${pageSlug}${window?.location?.search}`);
      }
    } catch (error) {
      console.error("Error prefetching new form page", error);
    }
  }, [redirectToNewForms]);

  return (
    <>
      <div className="flex flex-col w-4/12" id="petition-bar">
        {!ctaCompleted && (
          <div
            className="flex flex-col 2xl:w-3/12 lg:pe-4 2xl:pe-0 fixed"
            style={{ maxHeight: `${isMobile ? "100%" : "calc(100% - 120px)"}` }}
          >
            <div className="min-h-16 bg-mint-primary w-full text-black priFont-extraBold text-xl lg:flex hidden items-center ps-4 py-4 border border-solid border-gray-400 border-b-0">
              {cta || t("sign_it")}
            </div>

            {isMobile && (
              <div
                className="h-8 w-8 p-2 fixed top-2 end-2.5 flex justify-center items-center cursor-pointer z-10 rounded-full text-lg font-thin pb-3 border border-solid border-gray-200 "
                id="close-modal"
                onClick={() => openModal(false)}
              >
                x
              </div>
            )}

            <div
              className={`min-h-20 py-4 bg-gray-100 w-full text-black text-sm flex flex-col justify-center px-2 lg:border-l lg:border-r border-gray-400 ${
                isMobile ? "pe-8" : ""
              }`}
            >
              <Target target={target} />
              <span className="pt-2 overflow-scroll max-h-150">
                {description}
              </span>
            </div>
            <div
              className="h-full bg-white lg:border lg:border-t-0 border-gray-400 w-full relative rounded-sm overflow-scroll"
              id="petition-form"
            >
              <form onSubmit={handleSubmitForm} id="petition-fields">
                <div className="h-full p-4">
                  {!isMobile && (
                    <Thermometer
                      achieved={campaign_action_count + offset}
                      lang={language}
                      active={active}
                    />
                  )}
                  <Form
                    countries={countriesList}
                    fields={form}
                    errors={errObj}
                    loading={loading}
                  />
                </div>
                <div
                  className="bottom-0 h-20 w-full flex items-center justify-center"
                  id="cta-wrapper"
                >
                  {loading ? (
                    <Skeleton
                      containerTestId="submit-button-skeleton"
                      height={60}
                      width={330}
                    />
                  ) : (
                    <>
                      {isSubmitting ? (
                        <button
                          type="button"
                          disabled
                          className="mx-4 p-4 w-full uppercase text-white outline-0 flex items-center justify-center priFont-extraBold border rounded-md focus:outline-none text-sm lg:text-lg"
                        >
                          {t("processing")}
                        </button>
                      ) : (
                        <button
                          type="submit"
                          ref={ctaRef}
                          id="cta-button"
                          className="sou-button z-10 mx-4 p-4 w-full bg-purple-primary uppercase text-white outline-0 flex items-center justify-center priFont-extraBold border rounded-md focus:outline-none text-sm lg:text-lg"
                        >
                          {cta || t("sign_it")}
                        </button>
                      )}
                    </>
                  )}
                </div>
                <div
                  className="pb-4 text-sm text-gray-400 px-4"
                  data-testid="fine-print"
                >
                  {t.rich("fine_print", {
                    a: (children) => (
                      <a
                        href={t("privacy_url")}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-purple-primary"
                      >
                        {children}
                      </a>
                    ),
                  })}
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

const Target = ({ target }) => {
  const t = useTranslations("default");

  if (!target) return null;

  return (
    <h2 className="priFont-semiBold">
      <span>{t("target_prefix")}</span>: {target}
    </h2>
  );
};

export default PetitionBar;
