import { useSelector, useDispatch } from "react-redux";
import { useTranslations } from "next-intl";
import {
  getTitle,
  getCurrentStep,
  changeStep,
} from "../../../reducers/DonationReducer";
import { getPageSlug, getPageType } from "../../../reducers/PageReducer";
import Step from "./Step";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import analytics from "../../../lib/analytics";
import { GA4_BUTTON_CLICK } from "../../TrackingScripts/helpers";

const Stepper = (props) => {
  const dispatch = useDispatch();
  const t = useTranslations("default");
  const { steps, loading } = props;
  const title = useSelector(getTitle);
  const currentStep = useSelector(getCurrentStep);
  const slug = useSelector(getPageSlug);
  const pageType = useSelector(getPageType);

  const stepsMap = {
    0: t("step_title_amount"),
    1: t("step_title_details"),
    2: t("step_title_payment"),
  };

  const backButtonIsOn = useFeatureIsOn("back-button");
  const isSingleStepPostActionDonateOn = useFeatureIsOn(
    "single-step-post-action-donate"
  );
  const { _, __, donateCtaTitle } = useFeatureValue("single-pa-step-content", {
    title: "",
    donateAsk: "",
    donateCtaTitle: title,
  });

  const handleBack = (e) => {
    const previousStep = currentStep - 1;
    if (previousStep >= 0) {
      analytics.track(GA4_BUTTON_CLICK, {
        button_id: e.target?.id,
        button_text: e.target?.innerText,
        slug,
        action_page_type: pageType,
      });
      dispatch(changeStep(currentStep - 1));
    }
  };

  return (
    <div className="Stepper w-full md:w-4/5 lg:w-4/5 flex flex-col justify-center items-center">
      <h2 className="Stepper-header text-md font-black priFont-extraBold pb-2">
        {loading ? (
          <Skeleton containerTestId="stepper-header-skeleton" width={200} />
        ) : (
          title
        )}
      </h2>
      <div className="Stepper-steps py-4 flex flex-row justify-between w-4/5 relative">
        {steps.map((step, index) => {
          return (
            <Step
              loading={loading}
              step={step}
              index={index}
              key={Math.random()}
            />
          );
        })}
        <div className="Stepper-line absolute h-0.5 w-11/12 bg-gray-400 top-10"></div>
      </div>

      {backButtonIsOn &&
        currentStep > 0 &&
        (loading ? (
          <Skeleton width={200} />
        ) : (
          <button
            id={`back-button-${currentStep - 1}`}
            onClick={(e) => handleBack(e)}
            className="flex mb-2 justify-center items-center py-1 my-1 text-blue-link underline"
          >
            <FontAwesomeIcon
              icon={faAngleLeft}
              width={10}
              size="2xs"
              fixedWidth
              className="me-2 self-center"
            />
            {t("back_button", {
              count: currentStep - 1,
              step: stepsMap[currentStep - 1],
            })}
          </button>
        ))}
    </div>
  );
};

export default Stepper;
