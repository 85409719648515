import { useSelector } from "react-redux";
import { getMemberName } from "../../../reducers/MemberReducer";
import {
  getDonationAskBody,
  getDonationAskTitle,
  getPageSlug,
} from "../../../reducers/PageReducer";
import { useTranslations } from "next-intl";
import { useState, useEffect } from "react";

const ScrollToNewForm = () => {
  const slug = useSelector(getPageSlug);
  const t = useTranslations("default");
  const translate = useTranslations("fundForm");

  const [isLoading, setLoading] = useState(true);

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  const firstName = useSelector(getMemberName) || t("dear");
  const donationAskTitle =
    useSelector(getDonationAskTitle) || t("thank_you", { name: firstName });
  const donationAskBody = useSelector(getDonationAskBody);
  const donateAsk = donationAskBody
    ? donationAskBody
    : t("donate_thanks", { firstName });

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % 7); // 7 is the number of preloaded messages, check en.json loading_message_
    }, 3000); // milliseconds per message

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {donationAskTitle && (
        <h1 className="pb-4 lg:pb-10 lg:text-2xl text-xl text-center priFont-semiBold">
          {donationAskTitle}
        </h1>
      )}
      {donateAsk && (
        <p className="px-4 lg:px-0 pb-4 lg:pb-8 my-0 mx-auto max-w-2xl lg:text-xl text-lg text-center">
          {donateAsk}
        </p>
      )}
      {isLoading && (
        <div className="flex flex-col p-6 bg-white mx-2 min-h-screen relative">
          <h2
            className={`text-2xl text-black text-center priFont-extraBold mb-6`}
            data-testid="fundraiser-bar-title "
          >
            {translate("make_secure_donation")}
          </h2>
          <div className="w-full max-w-md h-96 p-6 px-6 lg:px-16 mx-auto my-8 flex items-center justify-center relative overflow-hidden rounded-lg">
            <div className="absolute w-full h-full bg-gray-400 animate-pulse"></div>
            <p className="relative z-10 text-center text-black text-lg">
              <span>{t("setting_up_things")}</span>
              <br />
              <br />
              <span>{t("in_meantime")}</span>
              <br />
              <strong>{t(`loading_message_${currentMessageIndex}`)}</strong>
            </p>
          </div>
        </div>
      )}
      <iframe
        src={`${window.location.origin}/fund/${slug}/compact`}
        width="100%"
        height="1600"
        allowFullScreen
        onLoad={() => setLoading(false)}
      ></iframe>
    </>
  );
};

export default ScrollToNewForm;
