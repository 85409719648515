import { useEffect, useState, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslations } from "next-intl";
import { setSharedOnSocialMedia } from "../../reducers/ActionFormReducer";
import { getPageSlug, getPageType } from "../../reducers/PageReducer";

import FacebookShareButton from "../ShareButtons/FacebookShareButton";
import TwitterShareButton from "../ShareButtons/TwitterShareButton";
import EmailShareButton from "../ShareButtons/EmailShareButton";
import WhatsappShareButton from "../ShareButtons/WhatsappShareButton";
import CopyToClipBoardButton from "../ShareButtons/CopyToClipBoardButton";

const Shares = forwardRef((props, ref) => {
  const [shareConsented, setShareConsented] = useState(false);
  const dispatch = useDispatch();
  const t = useTranslations("default");
  const slug = useSelector(getPageSlug);
  const pageType = useSelector(getPageType);

  useEffect(() => {
    if (shareConsented) {
      const scrollEl = document.getElementById("donation-section");
      scrollEl.classList.remove("hidden");
      scrollEl.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [shareConsented]);

  useEffect(() => {
    if (shareConsented) {
      dispatch(
        setSharedOnSocialMedia({ opted: true, completed: shareConsented })
      );
    }
  }, [shareConsented, dispatch]);

  return (
    <>
      <div id="share-options" className="w-full hidden" ref={ref}>
        <>
          <div className="lg:mx-24 mx-6 min-h-screen flex flex-col items-center md:justify-center justify-start pt-24">
            <div className="md:text-3xl text-2xl my-12 text-center">
              {t("followUp_shareCampaign")}
            </div>

            <div className="w-full flex flex-row flex-wrap items-center justify-center gap-y-6 gap-x-6 max-w-screen-desktop mx-auto">
              <FacebookShareButton
                slug={slug}
                pageType={pageType}
                setShareConsented={setShareConsented}
              />
              <TwitterShareButton
                slug={slug}
                pageType={pageType}
                setShareConsented={setShareConsented}
              />
              <EmailShareButton
                slug={slug}
                pageType={pageType}
                setShareConsented={setShareConsented}
              />
              <WhatsappShareButton
                slug={slug}
                pageType={pageType}
                setShareConsented={setShareConsented}
              />
              <CopyToClipBoardButton
                slug={slug}
                pageType={pageType}
                setShareConsented={setShareConsented}
              />
            </div>
          </div>
        </>
      </div>
    </>
  );
});

Shares.displayName = "Shares";

export default Shares;
