import { setCountry, resetForm } from "../reducers/ActionFormReducer";
import { resetConsent } from "../reducers/ConsentReducer";
import {
  seedFormValues,
  setRecurring,
  setSavedPaymentMethods,
  setShowSavedPayments,
} from "../reducers/DonationReducer";
import { setQueryParams } from "../reducers/PageReducer";
import { resetMember } from "../reducers/MemberReducer";
import {
  clearCookie,
  MEMBER_ID_KEY,
  PAYMENT_METHODS_KEY,
} from "../store/cookieUtils";
import { batchActions } from "./redux-utils";

export function removeSavedMemberDetails(dispatch) {
  clearCookie(MEMBER_ID_KEY);
  clearCookie(PAYMENT_METHODS_KEY);

  const actions = [
    resetMember(),
    setCountry(null),

    resetConsent(),
    setSavedPaymentMethods([]),
    setShowSavedPayments(false),
    setRecurring(false),
    seedFormValues({}),
    resetForm(),
    setQueryParams({}),
  ];
  batchActions(dispatch, actions);
}
