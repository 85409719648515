import { useTranslations } from "next-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  setConsent,
  getConsentError,
  getConsentValue,
  setChooseConsent,
  getChooseConsent,
} from "../../../reducers/ConsentReducer";
import { getIsMemberPresent, getMember } from "../../../reducers/MemberReducer";

const ConsentRadio = ({ value, label, onSelect, id }) => {
  function isSelected() {
    const applyBgColorClass = "text-purple-primary bg-purple-primary";
    switch (value) {
      case null:
        // If consent is not given show both options unselected
        return "";
      case true:
        // If consent is given show accept selected
        return id === "consent-accept" ? applyBgColorClass : "";
      case false:
        // If consent is not given show decline selected
        return id === "consent-decline" ? applyBgColorClass : "";
      default:
        break;
    }
  }

  return (
    <div
      className="flex flex-row items-center py-2 cursor-pointer"
      onClick={onSelect}
      id={`${id}-wrapper`}
    >
      <input
        id={id}
        type="radio"
        name="consent"
        onChange={onSelect}
        value={id.toUpperCase()}
        checked={value || ""}
        className={`border-none bg-gray-300  focus:ring-transparent ${isSelected()}`}
      />
      <label id={`${id}-text`} className="ps-2 text-md">
        {label}
      </label>
    </div>
  );
};
const Consent = () => {
  const t = useTranslations("default");

  const consentValue = useSelector(getConsentValue);
  const consentError = useSelector(getConsentError);
  const isMemberPresent = useSelector(getIsMemberPresent);
  const chooseConsent = useSelector(getChooseConsent);
  const member = useSelector(getMember);
  const dispatch = useDispatch();

  const handleConsentValue = () => {
    if (isMemberPresent && member?.consented === true) return true;
    if (consentValue !== null && chooseConsent === true) {
      return consentValue;
    } else {
      return undefined;
    }
  };

  return (
    <>
      {consentError && (
        <div className="bg-red-300 text-black text-md rounded-md rounded-b-none p-2 border border-red-600 border-b-0">
          {t("consent_select_an_option")}
        </div>
      )}
      <div
        className={`p-2 mb-2 border ${
          consentError ? "border-red-600 border-t-0" : "border-transparent"
        }`}
        id="consent"
      >
        <div className="flex ms-4 flex-col mb-4" id="consent-options">
          <ConsentRadio
            onSelect={() => {
              dispatch(setChooseConsent());
              dispatch(setConsent(true));
            }}
            value={handleConsentValue()}
            label={t("consent_accept")}
            id={`consent-accept`}
          />
          <ConsentRadio
            onSelect={() => {
              dispatch(setChooseConsent());
              dispatch(setConsent(false));
            }}
            value={handleConsentValue()}
            label={t("consent_decline")}
            id={`consent-decline`}
          />
        </div>
        {consentValue === false && chooseConsent && (
          <div
            className="border border-red-600 rounded-sm flex flex-col p-2 mb-4"
            id="consent-warn"
          >
            <div className="priFont-extraBold text-md pt-2 pb-4 text-red-600">
              {t("consent_opt_out_warn_title")}
            </div>
            <div className="text-md leading-snug text-red-600 ">
              {t("consent_opt_out_warn_message")}
            </div>
          </div>
        )}
        <div className="text-md leading-snug" id="how_to_opt_out">
          {t.rich("consent_how_to_opt_out", {
            a: (children) => (
              <a
                href={t("consent_unsubscribe_url")}
                target="_blank"
                rel="noopener noreferrer"
                className="text-purple-primary cursor-pointer"
              >
                {children}
              </a>
            ),
          })}
        </div>
      </div>
    </>
  );
};

export default Consent;
