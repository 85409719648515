import { useDispatch, useSelector } from "react-redux";
import CurrencyAmount from "../Helpers/CurrrencyAmount";
import {
  changeStep,
  selectDonationAmount,
  setCovertransactionCost,
  setIsCustomAmount,
  setPaymentMethods,
  setSelectedAmountButton,
  setGaItem,
} from "../../../reducers/DonationReducer";
import { getPageSlug, getPageType } from "../../../reducers/PageReducer";
import SkeletonWrapper from "../../common/SkeletonWrapper";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  GA4_ADD_ITEM_TO_CART,
  GA4_INITIATE_CHECKOUT,
} from "../../TrackingScripts/helpers";
import analytics from "../../../lib/analytics";
import { batchActions } from "../../../lib/redux-utils";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const AmountButton = (props) => {
  const { amount, currency, loading, dataTestId, name } = props;
  const slug = useSelector(getPageSlug);
  const pageType = useSelector(getPageType);

  const buttonLayoutClasses =
    "flex justify-center items-center rounded-md my-2 mx-0.5 h-16 w-46";

  const dispatch = useDispatch();
  const ga4Tracking = ({ amount, name }) => {
    const listName = "donation bands";
    const price = parseFloat(amount).toFixed(2);
    analytics.track(GA4_ADD_ITEM_TO_CART, {
      currency,
      value: price,
      slug,
      action_page_type: pageType,
      items: [
        {
          item_id: name,
          item_name: name,
          item_list_name: listName,
          price,
          quantity: 1,
        },
      ],
    });

    analytics.track(GA4_INITIATE_CHECKOUT, {
      currency: currency,
      value: price,
      slug,
      action_page_type: pageType,
      items: [
        {
          item_id: name,
          item_name: name,
          item_list_name: listName,
          price,
          quantity: 1,
        },
      ],
    });
  };
  const handleClick = (e) => {
    try {
      e.preventDefault();
      const actions = [];
      actions.push(setSelectedAmountButton(e.target.name));
      actions.push(setIsCustomAmount(false));
      actions.push(selectDonationAmount(amount));
      actions.push(changeStep(1));
      actions.push(setPaymentMethods());
      actions.push(setCovertransactionCost(false));
      actions.push(setGaItem({ id: name, listName: "donation bands" }));
      ga4Tracking({ amount, name });
      batchActions(dispatch, actions);
    } catch (error) {
      console.log(error);
    }
  };

  const redButtonOn = useFeatureIsOn("red-donation-button");

  return (
    <>
      {loading ? (
        <>
          <SkeletonWrapper
            className={buttonLayoutClasses}
            dataTestId="amount-buttons-skeleton"
          >
            <Skeleton width={153} height={64} />
          </SkeletonWrapper>
        </>
      ) : (
        <button
          data-testid={dataTestId}
          name={name}
          id={name}
          className={`DonationBand-Button ${buttonLayoutClasses} ${
            redButtonOn
              ? "bg-transparent border border-gray-400 hover:border-red-600"
              : "bg-purple-primary hover:bg-purple-hover"
          } focus:outline-none`}
          onClick={handleClick}
        >
          <span
            className={`text-2xl ${
              redButtonOn ? "text-bandTextExp" : "text-white"
            } pointer-events-none`}
          >
            <span className="font-extrabold priFont-extraBold pointer-events-none">
              <CurrencyAmount currency={currency} amount={amount} />
            </span>
          </span>
        </button>
      )}
    </>
  );
};

export default AmountButton;
