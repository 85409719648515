import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslations } from "next-intl";
import Stepper from "../Internal/Stepper";
import DonationBand from "./DonationBand";
import MemberDetails from "./MemberDetails";
import Payment from "./Payment";
import ProgressTracker from "../ProgressTracker";
import {
  fetchSavedPaymentMethods,
  getCurrentStep,
  getMerchantAccountId,
  setLocalPaymentClient,
} from "../../reducers/DonationReducer";
import {
  getIsMemberPresent,
  getMemberName,
} from "../../reducers/MemberReducer";
import {
  selectShowProgressTracker,
  getDonationAskBody,
  getDonationAskTitle,
} from "../../reducers/PageReducer";
import PaymentProcessing from "./Helpers/PaymentProcessing";
import { makeCancelable, importRetry } from "../Utils";
import ShareComponent from "./ShareComponent";

const Donation = (props) => {
  const {
    isMobile = false,
    openModal,
    pageIsLoading,
    scrollToDonate,
    buttons,
  } = props;

  const t = useTranslations("default");
  const dispatch = useDispatch();

  const isMemberPresent = useSelector(getIsMemberPresent);
  const currentStep = useSelector(getCurrentStep);
  const merchantAccountId = useSelector(getMerchantAccountId);
  const showProgressTracker = useSelector(selectShowProgressTracker);
  const firstName = useSelector(getMemberName) || t("dear");
  const donationAskBody = useSelector(getDonationAskBody);
  const donationAskTitle =
    useSelector(getDonationAskTitle) || t("thank_you", { name: firstName });

  const [steps, setSteps] = useState([
    { name: t("step_title_amount") },
    { name: t("step_title_details") },
    { name: t("step_title_payment") },
  ]);
  const [client, setClient] = useState(null);
  const [threeDSClient, setThreeDSClient] = useState(null);
  const [applePayClient, setApplePayClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deviceData, setDeviceData] = useState(null);
  const [braintree, setBraintree] = useState();
  const [braintreeClient, setBraintreeClient] = useState();
  const [dataCollector, setdataCollector] = useState();

  const donateAsk = donationAskBody
    ? donationAskBody
    : t("donate_thanks", { firstName });

  useEffect(() => {
    if (isMemberPresent) {
      setSteps([
        { name: t("step_title_amount") },
        { name: t("step_title_payment") },
      ]);

      // Fetch saved payment methods if the member is present
      try {
        dispatch(fetchSavedPaymentMethods());
      } catch (e) {
        console.log("FAILED", e);
      }
    } else {
      setSteps([
        { name: t("step_title_amount") },
        { name: t("step_title_details") },
        { name: t("step_title_payment") },
      ]);
    }
  }, [isMemberPresent, t, dispatch]);

  useEffect(() => {
    const { promise: braintreePromise, cancel: cancelBraintree } =
      makeCancelable(importRetry(() => import("braintree-web")));
    const { promise: braintreClientPromise, cancel: cancelBraintreeClient } =
      makeCancelable(importRetry(() => import("braintree-web/client")));
    const { promise: dataCollectorPromise, cancel: cancelDataCollector } =
      makeCancelable(importRetry(() => import("braintree-web/data-collector")));

    braintreePromise
      .then((braintree) => setBraintree(braintree))
      .catch((err) => console.log(err));
    braintreClientPromise
      .then((braintreeClient) => setBraintreeClient(braintreeClient))
      .catch((err) => console.log(err));
    dataCollectorPromise
      .then((dataCollector) => setdataCollector(dataCollector))
      .catch((err) => console.log(err));

    return () => {
      cancelBraintree?.();
      cancelBraintreeClient?.();
      cancelDataCollector?.();
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    try {
      if (braintree && braintreeClient && dataCollector) {
        setClient(null);
        setDeviceData(null);
        // Initialise braintree instance
        fetch(`/api/braintree/token?merchantAccountId=${merchantAccountId}`)
          .then((resp) => resp.json())
          .then((data) => {
            braintreeClient.create(
              { authorization: data.token },
              (error, client) => {
                braintree.applePay.create(
                  {
                    client: client,
                  },
                  function (applePayErr, applePayInstance) {
                    if (applePayErr) {
                      console.error(
                        "Error creating applePayInstance:",
                        applePayErr
                      );
                      return;
                    }
                    setApplePayClient(applePayInstance);
                  }
                );

                braintree.localPayment.create(
                  {
                    client: client,
                    merchantAccountId,
                  },
                  (localPaymentErr, localPaymentInstance) => {
                    if (localPaymentErr) console.error(localPaymentErr);
                    dispatch(setLocalPaymentClient(localPaymentInstance));
                  }
                );
                dataCollector.create(
                  {
                    client,
                    kount: true,
                    paypal: true,
                  },
                  (err, collectorInst) => {
                    if (err) {
                      if (isMounted) {
                        setClient(client);
                        setLoading(false);
                      }
                      return null;
                    }

                    const deviceData = collectorInst.deviceData;
                    if (isMounted) {
                      setClient(client);
                      setLoading(false);
                      setDeviceData(JSON.parse(deviceData));
                    }
                  }
                );
                braintree.threeDSecure.create(
                  {
                    client: client,
                    version: 2,
                  },
                  (err, threeDSInstance) => {
                    if (err) {
                      console.log(err);
                    }

                    if (isMounted) {
                      setThreeDSClient(threeDSInstance);
                    }
                  }
                );
              }
            );
          });
      }
    } catch (error) {
      setClient(null);
      setDeviceData(null);
      setLoading(false);
      console.log("payment token initialisation error", error);
    }

    return () => {
      isMounted = false;
    };
  }, [merchantAccountId, braintree, braintreeClient, dataCollector, dispatch]);

  return (
    <>
      <div
        id="fundraiser-view"
        className="mx-0 items-center flex-col flex lg:py-0 py-2 lg:px-0 px-6"
      >
        {scrollToDonate && showProgressTracker && (
          <ProgressTracker
            dataTestId="scrollToDonate-progress-tracker"
            classes="lg:hidden md:hidden justify-center"
          />
        )}
        <div className="flex justify-center items-center w-full">
          <PaymentProcessing />
          <div
            id="fundraiser-bar"
            className="flex flex-col justify-center items-center w-full"
          >
            {isMobile && (
              <div
                className="h-8 w-8 p-2 fixed top-2 end-2 flex justify-center items-center cursor-pointer z-10 rounded-full text-lg font-thin pb-3 border border-solid border-gray-200 "
                id="close-modal"
                onClick={() => openModal(false)}
              >
                x
              </div>
            )}
            {scrollToDonate && (
              <>
                {donationAskTitle && (
                  <h1 className="pb-4 lg:pb-10 lg:text-2xl text-xl text-center priFont-semiBold">
                    {donationAskTitle}
                  </h1>
                )}
                <p className="pb-4 lg:pb-8 my-0 mx-auto max-w-2xl lg:text-xl text-lg text-center">
                  {donateAsk}
                </p>
              </>
            )}

            <Stepper loading={pageIsLoading} steps={steps} />

            <div className="fundraiser-bar__main w-full lg:w-5/6 md:w-5/6">
              {currentStep === 0 && (
                <DonationBand
                  loading={pageIsLoading}
                  dataTestId="donation-band-wrapper"
                />
              )}
              {!isMemberPresent && currentStep === 1 && (
                <MemberDetails ctaText={t("button_proceedPayment")} />
              )}
              {currentStep + 1 === steps.length && (
                <Payment
                  client={client}
                  threeDSClient={threeDSClient}
                  applePayClient={applePayClient}
                  loading={loading || pageIsLoading}
                  deviceData={deviceData}
                  scrollToDonate={scrollToDonate}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="lg:hidden block w-full" id="floating-share-popup-mobile">
        <ShareComponent buttons={buttons} />
      </div>
    </>
  );
};

export default Donation;
