import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getShowCookiePreferenceBanner,
  setAcceptAll,
  setRejectOthers,
  setShowCookiePreferenceBanner,
} from "../../../reducers/CookiePreferenceReducer";
import { getCountry } from "../../../reducers/ActionFormReducer";
import { selectIsMobile } from "../../../reducers/PageReducer";
import { useTranslations } from "next-intl";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import cookies from "js-cookie";
import { isEnableGDPR, isDoubleOptInCountry } from "../../Utils";

const CookiePreferences = () => {
  const dispatch = useDispatch();
  const showCookiePrefBanner = useSelector(getShowCookiePreferenceBanner);
  const country = useSelector(getCountry);
  const isMobile = useSelector(selectIsMobile);
  const isCookieFeatureOn = useFeatureIsOn("cookie-preferences");
  const showRejectCookies = useFeatureIsOn("reject-cookies");
  const t = useTranslations("default");
  const [decisionMade, setDecisionMade] = useState(true);
  const [showBanner, setShowBanner] = useState(false);

  const sendConsent = useCallback((consent) => {
    gtag && gtag("consent", "update", consent);
  }, []);

  useEffect(() => {
    if (cookies.get("_eko_consent") !== undefined) {
      setDecisionMade(true);
    } else {
      setDecisionMade(false);
    }
  }, [cookies, setDecisionMade, sendConsent]);

  useEffect(() => {
    if (
      country &&
      (isEnableGDPR(country.value) || isDoubleOptInCountry(country.value))
    ) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  }, [country]);

  const handleDecision = (outcome) => {
    const consent = {
      ad_storage: outcome,
      analytics_storage: outcome,
      ad_user_data: outcome,
      ad_personalization: outcome,
    };

    const consentString = JSON.stringify(consent);

    cookies.set("_eko_consent", consentString, {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      path: "/",
    });
    sendConsent(consent);
    setDecisionMade(true);
    if (outcome === "granted") dispatch(setAcceptAll(true));
    dispatch(setShowCookiePreferenceBanner(false));
  };

  return (
    <>
      {isCookieFeatureOn && (
        <>
          {showBanner && (showCookiePrefBanner || !decisionMade) ? (
            <div
              data-testid="cookie-preference-modal"
              className="transition-transform 2xl:mx-auto mx-2 my-0 max-w-screen-desktop fixed p-2 inset-x-0 top-2 lg:z-50 z-high bg-white rounded-2xl border border-solid shadow"
            >
              <div className="flex lg:flex-row flex-col lg:items-center items-start justify-between">
                <div className="lg:flex-row flex-col flex lg:items-center items-start">
                  <div className="flex items-center space-x-2 shrink-0">
                    <h2
                      className="priFont-semiBold text-lg"
                      aria-label={t("banner_cookie_settings")}
                      data-testid="cookie-preference-title"
                    >
                      🍪
                      {isMobile ? (
                        <span>&nbsp;{t("banner_cookie_settings")}&nbsp;</span>
                      ) : (
                        <>&nbsp;</>
                      )}
                    </h2>
                    <FontAwesomeIcon
                      icon={faClose}
                      width={30}
                      onClick={() => handleDecision("denied")}
                      className="absolute top-2 right-2 p-2 cursor-pointer"
                      size="lg"
                    />
                  </div>
                  <p
                    className="lg:py-0 py-2 lg:px-2 px-0 text-md"
                    data-testid="cookie-preference-message"
                    aria-label={t("banner_cookie_consent_message")}
                  >
                    <span>{t("banner_cookie_consent_message")}</span>&nbsp;
                    <a
                      href={t("footer_privacy_policy_url")}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="pe-6 text-md priFont-semiBold underline"
                      data-testid="cookie-preference-privacy-link"
                    >
                      {t("footer_privacy_policy")}
                    </a>
                  </p>
                </div>
                <div className="flex flex-row">
                  {showRejectCookies && (
                    <button
                      data-testid="cookie-preference-reject-others"
                      className="bg-gray-200 p-2 text-black  rounded-md priFont-medium"
                      onClick={() => dispatch(setRejectOthers(true))}
                      aria-label={t("banner_reject_unneccessary")}
                    >
                      {t("banner_reject_unneccessary")}
                    </button>
                  )}
                  <button
                    data-testid="cookie-preference-accept-all"
                    className="bg-mint-primary p-2 text-black rounded-md priFont-semiBold lg:mx-10"
                    onClick={() => handleDecision("granted")}
                    aria-label={t("banner_accept")}
                  >
                    {t("banner_accept")}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <>
              {showRejectCookies && (
                <div
                  className="lg:hidden flex fixed top-65p right-1 rounded-2xl text-2xl justify-center items-center bg-white border z-20 border-solid"
                  id="cookie-preference-icon"
                  data-testid="cookie-preference-icon"
                  onClick={() => dispatch(setShowCookiePreferenceBanner(true))}
                >
                  🍪
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default CookiePreferences;
