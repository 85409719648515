import Image from "next/image";
import { buildURL } from "react-imgix";

const imgHost = process.env.NEXT_PUBLIC_IMG_HOST;

const HeroImage = ({ path, title }) => {
  const urlA = buildURL(`${imgHost}/${path}`, {
    crop: "edges",
    auto: "format",
    fit: "clip",
    w: 980,
    h: 500,
  });

  return (
    <Image
      src={urlA}
      alt={title}
      width={980}
      height={490}
      priority={true}
      loading="eager"
      sizes="(max-width: 600px) 100vw, 600px"
    />
  );
};

export default HeroImage;
