import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslations } from "next-intl";
import { getCountry } from "../../reducers/ActionFormReducer";
import { getCurrency } from "../../reducers/DonationReducer";
import CurrencyAmount from "../Donation/Helpers/CurrrencyAmount";
import { getPageSlug, getPageType } from "../../reducers/PageReducer";
import {
  getCountry as getCurrencyForCountry,
  formatCurrency,
} from "country-currency-map";
import analytics from "../../lib/analytics";
import { GA4_BUTTON_CLICK } from "../TrackingScripts/helpers";
import classNames from "classnames";
import { currencySymbols } from "../../utils/constants";

const EkoFundraiserPopup = () => {
  const donationBands = {
    oneTime: [5, 7],
    monthly: [5, 7],
  };

  const t = useTranslations("default");
  const supportedCurrencies = Object.keys(currencySymbols);

  const country = useSelector(getCountry);
  const currencyFromGeoLocation = useSelector(getCurrency);
  const { currency = currencyFromGeoLocation } =
    getCurrencyForCountry(country?.label) || {};
  const [selectedAmount, setSelectedAmount] = useState(
    donationBands.oneTime[1]
  );
  const slug = useSelector(getPageSlug);
  const pageType = useSelector(getPageType);

  const [selectedFrequency, setSelectedFrequency] = useState("oneTime");
  const [minimize, setMinimize] = useState(false);
  const [close, setClose] = useState(false);
  const [customAmount, setCustomAmount] = useState("");
  const [ready, setReady] = useState(false);

  const handleClose = () => {
    analytics.track(GA4_BUTTON_CLICK, {
      button_id: "close-fr-popup",
      button_text: t("fr_popup_close"),
      action_page_type: pageType,
      slug,
    });
    setClose(true);
  };

  const handleDonate = (e) => {
    e.preventDefault();
    const isSupportedCurrency = supportedCurrencies[currency] !== undefined;
    const selectedCurrency =
      currency != null && isSupportedCurrency ? currency : "USD";
    const url = `${t(
      "donate_page_url"
    )}?currency=${selectedCurrency}&amount=${selectedAmount}&source=frpopup&recurring_default=${
      selectedFrequency === "oneTime" ? "only_one_off" : "only_recurring"
    }`;
    analytics.track(GA4_BUTTON_CLICK, {
      button_id: "donate-fr-popup",
      button_text: t("button_donate_now"),
      action_page_type: pageType,
      slug,
    });
    window.open(url, "_blank");
  };

  const handleMinimize = (e) => {
    e.preventDefault();
    analytics.track(GA4_BUTTON_CLICK, {
      button_id: "minimize-fr-popup",
      button_text: `${!minimize ? "minimize" : "maximize"}`,
      action_page_type: pageType,
      slug,
    });
    setMinimize(!minimize);
  };

  const handleAmountChange = (e) => {
    e.preventDefault();
    const amount = e.target.value.replace(/\D/g, "");
    analytics.track(GA4_BUTTON_CLICK, {
      button_id: "setAmount-fr-popup",
      button_text: amount,
      action_page_type: pageType,
      slug,
    });
    setSelectedAmount(amount);
  };

  const handleCustomAmountChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    // Check if the value is a valid number
    if (!isNaN(value) && value.trim() !== "") {
      if (currency && supportedCurrencies[currency] !== undefined) {
        setCustomAmount(formatCurrency(value, currency));
      } else {
        setCustomAmount(value);
      }
      handleAmountChange(e);
    } else {
      setCustomAmount("");
      setSelectedAmount(donationBands[selectedFrequency][1]);
    }
  };

  const handleDonationFrequencyChange = (e, frequency) => {
    e.preventDefault();
    analytics.track(GA4_BUTTON_CLICK, {
      button_id: "setFrequency-fr-popup",
      button_text: frequency,
      action_page_type: pageType,
      slug,
    });
    setSelectedFrequency(frequency);
    if (donationBands[frequency].indexOf(selectedAmount) === -1)
      setSelectedAmount(donationBands[frequency][1]);
  };

  const sentences = t("fr_popup_message").split(".");
  const secondToLastIndex = sentences.length - 2;
  const lastSentence = sentences.length - 1;

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !ready) {
      setTimeout(() => {
        setReady(true);
      }, 2000);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {ready && (
        <aside
          id="beautiful-fr-popup"
          data-testid="beautiful-fr-popup"
          className={`overflow-y-auto fixed w-full p-6 h-auto max-h-screen-100 lg:max-h-96 bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 shadow-lg dark:bg-gray-800 ${
            close ? "hidden" : ""
          }`}
        >
          <div className="container mx-auto grid grid-cols-12 gap-x-6 gap-y-3 items-center text-white">
            <div
              className={`col-span-12  pl-0 order-1 sm:order-none ${
                minimize ? "lg:col-span-12" : "lg:col-span-8"
              }`}
            >
              <h2
                className={`text-3xl priFont-extraBold ${
                  minimize ? "text-center" : ""
                }`}
              >
                {t("fr_popup_title")}
              </h2>
            </div>
            <div
              className={`lg:-mt-14 col-span-12 pl-0 order-3 sm:order-none ${
                minimize ? "lg:col-span-12" : "lg:col-span-8"
              }`}
            >
              <p
                className={`text-lg mt-3 priFont-medium ${
                  minimize ? "hidden" : ""
                }`}
              >
                {sentences.map((sentence, index) => (
                  <span
                    key={index}
                    className={classNames("pe-1", {
                      "mb-4 bg-yellow-300 bg-opacity-75 px-1 rounded-md text-black":
                        index === secondToLastIndex,
                      "ps-1 priFont-extraBold": lastSentence === index,
                    })}
                  >
                    {sentence.trimStart() +
                      (index < sentences.length - 1 ? "." : "")}
                  </span>
                ))}
              </p>
            </div>
            <div
              className={`col-span-12  lg:col-span-12 ${
                !minimize ? "hidden" : ""
              }`}
            >
              <form className="flex flex-col space-y-4">
                <div className="flex justify-center mt-4">
                  <button
                    className="priFont-semiBold ring-offset-background focus-visible:ring-ring w-30 mb-4 inline-flex h-10 transform animate-subtleBounce items-center justify-center whitespace-nowrap rounded-md bg-white px-4 py-2 text-sm font-medium text-black shadow-lg transition-transform hover:scale-105 hover:bg-gray-200 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 group-hover:animate-none"
                    onClick={(e) => {
                      handleMinimize(e);
                    }}
                  >
                    {t("button_donate_now")}
                  </button>
                </div>
                <div className="flex justify-center mt-4">
                  <a
                    className="text-sm text-white underline cursor-pointer"
                    rel="ugc"
                    onClick={handleClose}
                  >
                    {t("fr_popup_close")}
                  </a>
                </div>
              </form>
            </div>
            <div
              className={`col-span-12  lg:col-span-4 order-1 sm:order-none ${
                minimize ? "hidden" : ""
              }`}
            >
              <form className="flex flex-col space-y-4">
                <div className="flex flex-col space-y-2">
                  <label
                    className="priFont-semiBold peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-base"
                    htmlFor="frequency"
                  >
                    {t("donation_frequency")}
                  </label>
                  <div className="flex space-x-2">
                    <label
                      className={`cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm priFont-semiBold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-10 px-4 py-2 w-full bg-white text-black hover:bg-gray-200 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white ${
                        selectedFrequency === "oneTime"
                          ? "bg-gray-200 text-gray-700 border-black border-solid border-2"
                          : "border border-input "
                      }`}
                    >
                      <input
                        type="radio"
                        name="frequency"
                        value="oneTime"
                        className="sr-only"
                        onChange={(e) =>
                          handleDonationFrequencyChange(e, "oneTime")
                        }
                      />
                      {t("one_time")}
                    </label>
                    <label
                      className={`cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm priFont-semiBold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-10 px-4 py-2 w-full bg-white text-black hover:bg-gray-200 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white ${
                        selectedFrequency === "monthly"
                          ? "bg-black-200 text-gray-700 border-black border-solid border-2"
                          : "border border-input "
                      }`}
                    >
                      <input
                        type="radio"
                        name="frequency"
                        value="monthly"
                        className="sr-only"
                        onChange={(e) =>
                          handleDonationFrequencyChange(e, "monthly")
                        }
                      />
                      {t("monthly")}
                    </label>
                  </div>
                </div>
                <div className="flex flex-col space-y-2">
                  <label
                    className="bold priFont-semiBold peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-base"
                    htmlFor="amount"
                  >
                    {t("step_title_amount")}
                  </label>
                  <div className="flex space-x-2">
                    {donationBands[selectedFrequency].map((amount, index) => {
                      return (
                        <label
                          key={index}
                          className={`cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm priFont-semiBold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-10 px-4 py-2 w-full bg-white text-black hover:bg-gray-200 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white ${
                            selectedAmount == amount
                              ? "bg-gray-200 text-gray-700 border-black border-solid border-2"
                              : "border border-input "
                          }`}
                        >
                          <input
                            key={index}
                            type="radio"
                            name="amount"
                            value={amount}
                            className="sr-only"
                            onChange={(e) => handleAmountChange(e)}
                            checked={amount == selectedAmount}
                          />
                          <CurrencyAmount currency={currency} amount={amount} />
                        </label>
                      );
                    })}
                    <input
                      className="flex h-10 w-full rounded-md border border-input px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:priFont-semiBold placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 bg-white text-black dark:bg-gray-800 dark:text-white hover:bg-gray-200 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                      placeholder={t("other")}
                      onChange={(e) => handleCustomAmountChange(e)}
                      value={customAmount}
                    />
                  </div>
                </div>
                <div className="flex justify-center mt-4">
                  <button
                    className="cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm priFont-semiBold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input h-10 px-4 py-2 w-full bg-white text-black hover:bg-gray-200 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    onClick={(e) => handleDonate(e)}
                  >
                    {t("button_donate_now")}
                  </button>
                </div>
              </form>
            </div>
            <button
              className="cursor-pointer absolute top-3 right-3 p-2"
              onClick={(e) => {
                handleMinimize(e);
              }}
            >
              {minimize ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="24" height="24" fill="url(#pattern0)" />
                  <defs>
                    <pattern
                      id="pattern0"
                      patternContentUnits="objectBoundingBox"
                      width="1"
                      height="1"
                    >
                      <use href="#image0_2307_8" transform="scale(0.0333333)" />
                    </pattern>
                    <image
                      id="image0_2307_8"
                      width="24"
                      height="24"
                      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA4klEQVR4nO3UMUoDQRSH8dUiWAg2QtLnAFaWWnkFS1uvkCvkDintBQs7xUYhyt7BxiYByyBIyC8sLijruLskE5vMB9Ms838fM7PvZVkikdhK0MdLufr/JT3BxDfvONu09BKffjPHYBPCDkaaucJeLGkXjwHJTbmqFHu760qP8FopvMAQu9gprrj89pM3HK8qPcesUvADF+vs/ZNVT9F0O1kd2Md14N2e0KsNf+UP8RDI3+KgLvgcCBV/c6dJ2qIDxnWhaL0Z6vmshTjKNMIppm3E98hjzt9yrue4i1UzkUhsOUuAWBmfY6Ll2QAAAABJRU5ErkJggg=="
                    />
                  </defs>
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="24" height="24" fill="url(#pattern0)" />
                  <defs>
                    <pattern
                      id="pattern0"
                      patternContentUnits="objectBoundingBox"
                      width="1"
                      height="1"
                    >
                      <use href="#image0_2307_9" transform="scale(0.0333333)" />
                    </pattern>
                    <image
                      id="image0_2307_9"
                      width="24"
                      height="24"
                      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA6ElEQVR4nO3UMUoDQRTG8dUiWAg2QtLnAFaWWnkFS9tcwSt4B0t7wcJSbRTWImdIkyZCyhAQyU8WNhDGZXeSTCPMH6Z73/cxb+a9oshkMpkU4BVjDBN6DmvPl7aiNXNcJQi9xNfaNCa44ge3e4SO8L1pWLQUl/5yj94Wgb1aE1K2iY7x2CD6wCAi9BRvDfpnnHSJD6oWYxWIpzhv0Z1hEmgqjzscdrZqw+gai8BoiZt9aqPousWu3YkCfbw3vNtTfUKq2n6c++4/NeQBR0VqNMxmipmPAheYpd5y2+zfz/ok2+uZTOZ/8QttCRmgpVxKagAAAABJRU5ErkJggg=="
                    />
                  </defs>
                </svg>
              )}
            </button>
          </div>
        </aside>
      )}
    </>
  );
};

export default EkoFundraiserPopup;
