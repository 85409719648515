import React, { useEffect, useRef, useState } from "react";
import { useTranslations } from "next-intl";
import classNames from "classnames";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const Input = (props) => {
  const [isFocussed, setFocus] = useState(false);
  const {
    value,
    errorMsg,
    minLength,
    maxLength,
    required,
    autoFocus,
    id,
    name,
    onFocus,
    onBlur,
    onChange,
    placeholder,
    disabled,
    type = "text",
    hideField,
    hidden,
    pattern,
    title,
  } = props;

  const t = useTranslations("default");
  const isEmailField = type === "email";
  const inputRef = useRef(null);
  const redButtonOn = useFeatureIsOn("red-donation-button");

  useEffect(() => {
    if (isFocussed) inputRef.current.focus();
  }, [isFocussed]);

  return (
    <>
      {!hideField && (
        <div
          key={name}
          className={`mb-2 relative ${hideField ? "hidden" : "block"}`}
        >
          <label
            htmlFor={id}
            className={`placeholderLabel absolute left-3 z-10 cursor-text pointer-events-auto transition-all	transform text-gray-400  ${
              inputRef?.current?.value?.length > 0 ||
              value?.length > 0 ||
              isFocussed
                ? "scale-75 origin-top-left top-1 full"
                : "scale-100 top-1/4"
            } ${
              isFocussed
                ? redButtonOn
                  ? "text-red-700 active"
                  : "text-purple-primary active"
                : ""
            } `}
            onClick={() => {
              setFocus(true);
            }}
          >
            {placeholder}
            {!required && (
              <span className="text-gray-400 text-xs italic">
                {" "}
                ({t("optional")})
              </span>
            )}
          </label>
          <input
            className={classNames(
              `disabled:bg-gray-700 disabled:cursor-not-allowed w-full focus:outline-none px-3 py-5 bg-gray-100 rounded-sm ${
                redButtonOn ? "" : "focus:border-purple-primary"
              } focus:ring-transparent text-black  ${
                errorMsg
                  ? "border-2 border-red-600"
                  : "border border-transparent"
              } pb-2`,
              { lowercase: isEmailField }
            )}
            ref={inputRef}
            maxLength={maxLength}
            minLength={minLength}
            defaultValue={value}
            required={required}
            autoFocus={autoFocus}
            id={id}
            name={name}
            type={type}
            hidden={hidden}
            onFocus={() => {
              setFocus(true);
            }}
            onBlur={() => {
              setFocus(false);
            }}
            onChange={onChange}
            disabled={disabled}
            pattern={pattern}
            title={title}
            onKeyDown={(e) => {
              if (name !== "name") return;
              if (e?.key === "@" || e?.key === "/") e.preventDefault();
            }}
          />
          {errorMsg && (
            <div className="text-red-600 text-sm pt-2">{errorMsg}</div>
          )}
        </div>
      )}
    </>
  );
};

export default Input;
