import { useTranslations } from "next-intl";
import CurrencyAmount from "../../Donation/Helpers/CurrrencyAmount";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  getCurrentStep,
  changeStep,
  getDonationAmount,
  getCurrency,
} from "../../../reducers/DonationReducer";
import { getPageSlug, getPageType } from "../../../reducers/PageReducer";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import analytics from "../../../lib/analytics";
import { GA4_BUTTON_CLICK } from "../../TrackingScripts/helpers";
import { getQueryParams } from "../../../reducers/PageReducer";

const Step = (props) => {
  const t = useTranslations("default");
  const { index, step, loading } = props;
  const { name } = step;
  const dispatch = useDispatch();
  const currentStep = useSelector(getCurrentStep);
  const amount = useSelector(getDonationAmount);
  const currency = useSelector(getCurrency);
  const queryParams = useSelector(getQueryParams);
  const slug = useSelector(getPageSlug);
  const pageType = useSelector(getPageType);
  const { weekly } = queryParams;

  const stepWrapperClasses =
    "w-12 h-12 flex items-center justify-center rounded-full text-xl lg:font-semibold font-medium z-10";
  const redButtonOn = useFeatureIsOn("red-donation-button");

  const handleStepClick = () => {
    if (currentStep > index) {
      dispatch(changeStep(index));
      analytics.track(GA4_BUTTON_CLICK, {
        button_id: `step-${index + 1}`,
        button_text: index + 1,
        slug,
        action_page_type: pageType,
      });
    }
  };

  function SkeletonStepWrapper({ children }) {
    return (
      <div data-testid="steps-circles-skeleton" className={stepWrapperClasses}>
        {children}
      </div>
    );
  }

  return (
    <div className="Step-wrapper flex flex-col">
      {loading ? (
        <Skeleton circle height="100%" wrapper={SkeletonStepWrapper} />
      ) : (
        <>
          <div
            data-testid={`step-${index + 1}`}
            className={`Step ${stepWrapperClasses} ${
              currentStep === index
                ? redButtonOn
                  ? "bg-red-700 text-white Step-active"
                  : "bg-purple-primary text-white Step-active"
                : "bg-gray-300"
            } ${
              currentStep > index
                ? redButtonOn
                  ? "bg-gray-400 hover:bg-red-700 cursor-pointer Step-complete"
                  : "bg-gray-400 hover:bg-purple-primary cursor-pointer Step-complete"
                : ""
            }`}
            onClick={(e) => {
              handleStepClick(e);
            }}
            key={index + Math.random()}
          >
            {t(`${index + 1}`)}
          </div>
        </>
      )}
      <span
        className="Step-label pt-2 lg:font-semibold font-medium text-sm text-center"
        data-testid={`step-${index + 1}-label`}
      >
        {loading ? (
          <Skeleton containerTestId="steps-labels-skeleton" />
        ) : (
          <>
            {index === 0 && amount ? (
              <CurrencyAmount
                amount={
                  weekly === "true" ? Math.floor(amount / 4) || 1 : amount
                }
                currency={currency}
              />
            ) : (
              name
            )}
          </>
        )}
      </span>
    </div>
  );
};

export default Step;
