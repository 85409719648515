import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslations } from "next-intl";
import { formatCurrency } from "../../../app/components/donation/Utils";
import CurrencySelector from "../DonationBand/CurrenySelector";
import {
  selectCapDonationBands,
  setDonationAmount,
  setGaItem,
} from "../../../reducers/DonationReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { GA4_BUTTON_CLICK } from "../../TrackingScripts/helpers";
import analytics from "../../../lib/analytics";

const DonationSummary = ({ amount, currency }) => {
  const t = useTranslations("default");
  const dispatch = useDispatch();
  const donationBands = useSelector(selectCapDonationBands);
  const [showCurrencyCombobox, setShowCurrencyCombobox] = useState(false);
  const [showCustomAmount, setShowCustomAmount] = useState(false);
  const [showIcon, setShowIcon] = useState(false);

  let timerId = null;

  const handleAmountChange = (e) => {
    e.preventDefault();
    if (timerId) {
      clearTimeout(timerId);
    }
    const value = Number(e.target.value);
    if (value > 0 && !isNaN(value)) {
      setShowIcon(true);
      dispatch(setDonationAmount(Number(value)));
      dispatch(
        setGaItem({
          id: "custom-amount",
          listName: "other amount summary input",
        })
      );
    }
    timerId = setTimeout(() => {
      if (showIcon) handleGaTracking("custom-amount-summary-input", t("other"));
      setShowCustomAmount(false);
      setShowIcon(false);
      timerId = null;
    }, 2000);
  };

  const handleGaTracking = (id, text) => {
    analytics.track(GA4_BUTTON_CLICK, {
      button_id: id,
      button_text: text,
    });
  };

  return (
    <section id="donation-summary" className="mt-2 mb-4">
      <h2 className="text-lg font-semibold text-black">
        {t("donation_summary_title")}
      </h2>

      <div className="h-0.5 bg-gray-200 w-full my-2"></div>
      <div className="grid grid-cols-2 gap-6">
        <span className={`text-sm text-black`}>
          {t("donation_summary_total_amount")}
        </span>
        <div
          className={`text-sm text-black text-right flex justify-end items-baseline `}
        >
          {showCustomAmount ? (
            <>
              <div className="relative flex justify-end items-center">
                <input
                  className={`border-input ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex h-10 w-2/3 rounded-md border px-3 text-center py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50`}
                  id="DonationBands-custom-amount"
                  name="DonationBands-custom-amount"
                  placeholder={t("other")}
                  aria-label={t("other")}
                  onBlur={(e) => {
                    handleAmountChange(e);
                  }}
                  onChange={(e) => {
                    handleAmountChange(e);
                  }}
                />
                {showIcon && (
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    fixedWidth
                    className="absolute right-2 top-1/2 transform -translate-y-1/2"
                    style={{ color: "#63E6BE" }}
                  />
                )}
              </div>
            </>
          ) : (
            formatCurrency(amount?.toString() ?? "", currency)
          )}
          <button
            className="cursor-pointer"
            tabIndex={0}
            onClick={() => {
              setShowCustomAmount(true);
              handleGaTracking("amount-pencil", "edit");
            }}
            aria-label={t("donation_summary_edit")}
          >
            <FontAwesomeIcon
              icon={faPencil}
              size="sm"
              fixedWidth
              className="ms-2"
            />
          </button>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-6 my-2">
        <span className="text-sm text-black">
          {t("donation_summary_currency")}
        </span>
        <div
          className={`text-sm text-black text-right flex justify-end items-baseline `}
        >
          {currency}
          <button
            className="cursor-pointer"
            tabIndex={0}
            onClick={() => {
              setShowCurrencyCombobox(true);
              handleGaTracking("currency-pencil", "edit");
            }}
            aria-label={t("switchCurrency")}
          >
            <FontAwesomeIcon
              icon={faPencil}
              size="sm"
              fixedWidth
              className="ms-2"
            />
          </button>
        </div>
      </div>
      {showCurrencyCombobox && (
        <div className="h-12 my-3">
          <CurrencySelector
            id="currency-selector"
            donationBands={donationBands}
          />
        </div>
      )}
    </section>
  );
};

export default DonationSummary;
