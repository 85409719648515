// Localisation

export const DonationPagesByLanguage = {
  en: "https://action.eko.org/a/donate",
  de: "https://action.eko.org/a/spende",
  fr: "https://action.eko.org/a/soutiner-eko-donate-french",
  es: "https://action.eko.org/a/ayudanos-a-seguir-luchando-contra-la-avaricia-empresarial-en-2024",
  pt: "https://action.eko.org/a/ajude-nos-a-continuar-nosso-trabalho-de-combate-a-ganancia-corporativa-em-2024",
  nl: "https://action.eko.org/a/nl-doneren",
  ar: "https://action.eko.org/a/donate",
};

export const SupportedCountryLanguageList = [
  "en-US",
  "en-GB",
  "en-CA",
  "en-NZ",
  "en-AU",
  "en-IE",
  "de-DE",
  "de-AT",
  "de-CH",
  "fr-FR",
  "fr-CA",
  "es-ES",
  "es-AR",
  "es-MX",
];

export const SupportedLanguagesToDefaultLocaleMap = {
  en: "en_GB",
  de: "de_DE",
  fr: "fr_FR",
  es: "es_ES",
  pt: "pt_PT",
  nl: "nl_NL",
  ar: "ar_AR",
};

export const currencySymbols = {
  USD: "$",
  NZD: "$",
  CAD: "$",
  MXN: "$",
  BRL: "$",
  ARS: "$",
  AUD: "$",
  GBP: "£",
  CHF: "CHF ",
  EUR: "€",
};

export const PlaceholderBase64Image =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAATsAAADqCAYAAADdyeL5AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASjSURBVHgB7d2xTWNZGEDhN6vNJyAnIIYOqICAnA6oACRyAqgAKqADOiAmICcgd0AFs/uQkFjWMPbDhpk53ychGRvJCTq69/3Xft9ms9mPAeAP99cAECB2QILYAQliBySIHZAgdkCC2AEJYgckiB2QIHZAgtgBCWIHJIgdkCB2QILYAQliBySIHZAgdkCC2AEJYgckiB2QIHZAgtgBCWIHJIgdkCB2QILYAQliBySIHZAgdkCC2AEJYgckiB2QIHZAgtgBCWIHJIgdkCB2QILYAQliBySIHZAgdkCC2AEJYgckiB2QIHZAgtgBCWIHJIgdkCB2QILYAQliBySIHZAgdkCC2AEJYgckiB2QIHZAgtgBCWIHJIgdkCB2QILYAQliBySIHZAgdkCC2AEJYgckiB2QIHZAgtgBCWIHJIgdkCB2QILYAQliBySIHZAgdkCC2AEJYgckiB2QIHZAgtgBCWIHJIgdkCB2QILYAQliBySIHZAgdkCC2AEJYgckiB2QIHZAgtgBCWIHJIgdkCB2QILYAQliBySIHZAgdkCC2AEJYgck/D3ARGdnZ8Pl5eXw+Pg4fIbv378PBwcHw+np6QDLEjsmOTk5eQrdZxqjenFx8RS9o6OjAZbxbTab/RhgSVtbW5+2onttjN39/f0Ay7CyY5J5odvd3R22t7efHl9fXw8PDw/DOnxVZPm9WdkxycbGxn9+Pz4+/t/W8vz8/Om63jr8+387wDLEjklexm5zc3O4vb2d+3f7+/vDzc3NsGpix7IcPeHD9vb23nxtZ2dngF+B2PHLODw8fPqBdTCg4MPu7u7efG0cVCzi5TW/cdq6rmt9dFnZ8WHjNbl5cRqfW2Qi+3q4MT4en4NVMqBgktfT2NE4qHi+fjeu9hYZTMyb4j57b5prQMGyxI5J5sVuWe+F7tlbwRM7lmUby1r8bNiwSOhGtrSsipUdk7y3snsZsnkrs0VD9977WdmxLCs7Vupnw4YpoYNVcPSElXkrZK/jB1/BNpZJFvls7DrfzzaWZYkdk3x2fMSOj3LNDkgQOyDBgIKVWMUhY1gnKzsmGT+sX3xvfl9ixyRf+VVM4x3GYFm2sUwyHjMZ7wVxdXX1qbdSHCPrrB5TOHoCJNjGAgliBySIHZAgdkCC2AEJYgckiB2QIHZAgtgBCWIHJIgdkCB2QILYAQliBySIHZAgdkCC2AEJYgckiB2QIHZAgtgBCWIHJIgdkCB2QILYAQliBySIHZAgdkCC2AEJYgckiB2QIHZAgtgBCWIHJIgdkCB2QILYAQliBySIHZAgdkCC2AEJYgckiB2QIHZAgtgBCWIHJIgdkCB2QILYAQliBySIHZAgdkCC2AEJYgckiB2QIHZAgtgBCWIHJIgdkCB2QILYAQliBySIHZAgdkCC2AEJYgckiB2QIHZAgtgBCWIHJIgdkCB2QILYAQliBySIHZAgdkCC2AEJYgckiB2QIHZAgtgBCWIHJIgdkCB2QILYAQliBySIHZAgdkCC2AEJYgckiB2QIHZAgtgBCWIHJIgdkCB2QILYAQliBySIHZAgdkCC2AEJYgckiB2QIHZAwj+R+8zO69A0cQAAAABJRU5ErkJggg==";
export const BlurBase64Image =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mM8euBUPQAHcQLQWlCRZAAAAABJRU5ErkJggg==";

export const FollowUpDefaultImage =
  "https://sumofus.imgix.net/unique/sou-post-donate-bg.jpg"; // Default image on the post donate recurring ask

const keyStr =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

const triplet = (e1: number, e2: number, e3: number) =>
  keyStr.charAt(e1 >> 2) +
  keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
  keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
  keyStr.charAt(e3 & 63);

export const rgbDataURL = (r: number, g: number, b: number) => {
  return `data:image/gif;base64,R0lGODlhAQABAPAA${
    triplet(0, r, g) + triplet(b, 255, 255)
  }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`;
};
