import {
  ApplePayPayload,
  ApplePay,
  ApplePayPaymentRequest,
} from "braintree-web/apple-pay";

const processApplePay = async ({
  client,
  amount,
  currencyCode,
  supportedNetworks,
}: {
  client: ApplePay;
  amount: string;
  currencyCode: string;
  supportedNetworks: string[];
}) => {
  return new Promise((resolve, reject) => {
    if (!client) {
      reject("applepay_flow_failed");
    } else {
      const paymentRequest: ApplePayPaymentRequest =
        client.createPaymentRequest({
          total: {
            label: "Ekō Donation",
            amount: amount,
          },
          currencyCode: currencyCode,
          supportedNetworks: supportedNetworks,
          requiredBillingContactFields: ["postalAddress"],
          requiredShippingContactFields: ["email", "name"],
        });

      const session = new ApplePaySession(
        3,
        paymentRequest as ApplePayJS.ApplePayPaymentRequest
      );

      session.oncancel = function () {
        console.log("Apple Pay was cancelled.");
        reject("applepay_cancelled");
      };

      session.onvalidatemerchant = function (event) {
        client.performValidation(
          {
            validationURL: event.validationURL,
            displayName: "Ekō",
          },
          function (err, merchantSession) {
            if (err) {
              reject("applepay_flow_failed");
            }
            session.completeMerchantValidation(merchantSession);
          }
        );
      };

      session.onpaymentauthorized = function (event) {
        client.tokenize(
          {
            token: event.payment.token,
          },
          function (tokenizeErr, payload: ApplePayPayload) {
            if (tokenizeErr) {
              session.completePayment(ApplePaySession.STATUS_FAILURE);
              reject("applepay_flow_failed");
            }
            const { shippingContact, billingContact } = event.payment;
            resolve({
              ...payload,
              billingContact: billingContact,
              shippingContact: shippingContact,
            });
            session.completePayment(ApplePaySession.STATUS_SUCCESS);
          }
        );
      };
      session.begin();
    }
  });
};

export default processApplePay;
