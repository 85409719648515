import { useState } from "react";
import { useTranslations } from "next-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrency,
  getDonationAmount,
  getIsCustomAmount,
  selectDonationAmount,
  setCovertransactionCost,
  setIsCustomAmount,
  changeStep,
  setPaymentMethods,
  setSelectedAmountButton,
  setGaItem,
} from "../../../reducers/DonationReducer";
import { getPageSlug, getPageType } from "../../../reducers/PageReducer";
import CurrencyAmount from "../Helpers/CurrrencyAmount";
import SkeletonWrapper from "../../common/SkeletonWrapper";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { batchActions } from "../../../lib/redux-utils";
import {
  GA4_ADD_ITEM_TO_CART,
  GA4_INITIATE_CHECKOUT,
  GA4_BUTTON_CLICK,
} from "../../TrackingScripts/helpers";
import analytics from "../../../lib/analytics";
import { formatCurrency } from "../../../app/components/donation/Utils";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const CustomAmount = (props) => {
  const { loading } = props;
  const dispatch = useDispatch();

  const CustomAmountUxOn = useFeatureIsOn("custom-amount-ux");
  const amount = useSelector(getDonationAmount);
  const currency = useSelector(getCurrency);
  const isCustomAmount = useSelector(getIsCustomAmount);
  const slug = useSelector(getPageSlug);
  const pageType = useSelector(getPageType);

  const t = useTranslations("default");
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");

  function customAmountUxOnHandleCustomAmountChange(value) {
    const onlyAmountValue = value.replace(/[^0-9.]/g, "");
    if (onlyAmountValue && !isNaN(parseFloat(onlyAmountValue))) {
      const formattedValue = formatCurrency(onlyAmountValue, currency);
      setInputValue(formattedValue);
      dispatch(setIsCustomAmount(true, onlyAmountValue));
      dispatch(selectDonationAmount(parseFloat(onlyAmountValue)));
      dispatch(setCovertransactionCost(false));
    } else {
      dispatch(setIsCustomAmount(false, onlyAmountValue));
      dispatch(selectDonationAmount(null));

      setInputValue("");
    }
  }

  function handleCustomAmountChange(value) {
    if (value && !isNaN(parseFloat(value))) {
      dispatch(setIsCustomAmount(true, value));
      dispatch(selectDonationAmount(parseFloat(value)));
      dispatch(setCovertransactionCost(false));
    } else {
      dispatch(setIsCustomAmount(false, value));
      dispatch(selectDonationAmount(null));
    }
  }

  const ga4Tracking = ({ amount }) => {
    const itemName = "custom-amount";
    const listName = "other amount input";
    const price = parseFloat(amount).toFixed(2);
    analytics.track(GA4_ADD_ITEM_TO_CART, {
      currency,
      value: price,
      slug,
      action_page_type: pageType,
      items: [
        {
          item_id: itemName,
          item_name: itemName,
          item_list_name: listName,
          price,
          quantity: 1,
        },
      ],
    });

    analytics.track(GA4_INITIATE_CHECKOUT, {
      currency: currency,
      value: price,
      slug,
      action_page_type: pageType,
      items: [
        {
          item_id: itemName,
          item_name: itemName,
          item_list_name: listName,
          price,
          quantity: 1,
        },
      ],
    });
  };

  const handleClick = (e) => {
    if (!isCustomAmount || !amount) {
      e.preventDefault();
      return;
    }
    try {
      e.preventDefault();
      const actions = [];
      actions.push(setSelectedAmountButton(e.target.name));
      actions.push(setIsCustomAmount(false));
      actions.push(selectDonationAmount(amount));
      actions.push(changeStep(1));
      actions.push(setPaymentMethods());
      actions.push(setCovertransactionCost(false));
      actions.push(
        setGaItem({ id: "custom-amount", listName: "other amount input" })
      );
      ga4Tracking({ amount });
      analytics.track(GA4_BUTTON_CLICK, {
        id: "custom-amount-arrow",
        text: "arrow icon",
        slug,
        action_page_type: pageType,
      });
      batchActions(dispatch, actions);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading ? (
        <>
          <SkeletonWrapper className="flex justify-center items-center rounded-md my-2 mx-0.5 h-16 w-46">
            <Skeleton height={64} width={153} />
          </SkeletonWrapper>
        </>
      ) : (
        <>
          {CustomAmountUxOn ? (
            <div
              className={`${
                isFocused
                  ? "border-2 border-blue-ring"
                  : "border border-gray-300"
              } flex items-center rounded-md  bg-white w-46 h-16 dark:border-gray-600 dark:bg-gray-700`}
            >
              <input
                placeholder={t("other")}
                type="text"
                inputMode="numeric"
                value={inputValue ? inputValue : ""}
                id="DonationBands-custom-amount"
                className="focus:ring-0 flex-grow h-16 border text-md text-center  file:border-0 file:bg-transparent file:text-sm file:font-medium disabled:cursor-not-allowed disabled:opacity-50 w-full rounded-md border-none bg-transparent px-3 py-2 text-gray-900 outline-none placeholder:text-gray-400"
                onChange={(e) =>
                  customAmountUxOnHandleCustomAmountChange(e.target.value)
                }
                style={{ outline: "none" }}
                onFocus={() => setIsFocused(true)}
                onKeyDown={(e) => {
                  // Only allow the user to enter numbers with decimal places
                  if (
                    (e.key === "." && e.target.value.includes(".")) ||
                    (!/^\d*\.?\d*$/.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "Delete")
                  ) {
                    e.preventDefault();
                  }
                  if (e.key === "Enter") {
                    handleClick(e);
                  }
                }}
              />
              <button
                className={`${
                  isCustomAmount
                    ? "bg-purple-primary hover:bg-purple-hover"
                    : "bg-gray-100 hover:bg-gray-200 hover:text-gray-600"
                } inline-flex items-center justify-center whitespace-nowrap text-sm font-medium transition-colors disabled:pointer-events-none disabled:opacity-50 rounded-md border-l border-gray-200 p-2 text-gray-500 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400 dark:hover:bg-gray-500 dark:hover:text-gray-300 w-1/2 h-full`}
                onClick={handleClick}
                style={{ outline: "none" }}
              >
                <FontAwesomeIcon
                  icon={faArrowRight}
                  fixedWidth
                  size="sm"
                  className="h-4 w-4"
                  color={`${isCustomAmount ? "white" : ""}`}
                />
              </button>
            </div>
          ) : (
            <input
              placeholder={t("other")}
              type="number"
              step="5"
              min="1"
              defaultValue={
                isCustomAmount
                  ? CurrencyAmount({ amount, currency })
                  : undefined
              }
              id="DonationBands-custom-amount"
              className="DonationBands-input w-46 h-16 focus:outline-none text-center bg-gray-100 rounded-sm border-gray-300 focus:border-purple-primary focus:ring-transparent text-black border my-2 mx-0.5"
              onChange={(e) => handleCustomAmountChange(e.target.value)}
            />
          )}
        </>
      )}
    </>
  );
};

export default CustomAmount;
