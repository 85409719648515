import { useSelector } from "react-redux";
import { getPageSlug } from "../../../reducers/PageReducer";
import SharePanel from "../../Internal/SharePanel";
import { useTranslations } from "next-intl";

const ShareAskPanel = ({ buttons }) => {
  const slug = useSelector(getPageSlug);
  const translate = useTranslations("default");

  return (
    <div
      id="share-ask-with-panel"
      className="mt-48 flex flex-col items-center lg:px-0 px-2 relative"
    >
      <hr className="w-full border border-black-raisin absolute top-3 z-10" />
      <p className="pb-4 text-lg z-20 bg-white px-2">
        <strong>{translate("share_ask_title")}</strong>
      </p>
      <p className="text-center">{translate("share_ask_msg")}</p>
      <SharePanel buttons={buttons} slug={slug} classes="w-full" />
    </div>
  );
};

export default ShareAskPanel;
