import { forwardRef } from "react";
import CurrencyAmount from "../Helpers/CurrrencyAmount";
import { useSelector } from "react-redux";
import { useTranslations } from "next-intl";
import { selectLanguage } from "../../../reducers/PageReducer";

import {
  getCurrency,
  getDonationAmount,
  selectBaseFacebookTrackingData,
} from "../../../reducers/DonationReducer";
import { getMemberId } from "../../../reducers/MemberReducer";
import { getPageSlug, getPageType } from "../../../reducers/PageReducer";
import {
  GA4_BUTTON_CLICK,
  FB_EV_INITIATE_CHECKOUT,
} from "../../TrackingScripts/helpers";
import analytics from "../../../lib/analytics";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { logEventToFacebook } from "../../TrackingScripts/LogPixel";

const DonateButton = forwardRef((props, ref) => {
  const {
    submitting,
    name,
    recurring,
    weekly,
    disabled,
    onClick,
    classes,
    applePay,
  } = props;
  const amount = useSelector(getDonationAmount);
  const currency = useSelector(getCurrency);
  const slug = useSelector(getPageSlug);
  const pageType = useSelector(getPageType);
  const lang = useSelector(selectLanguage);
  const baseFacebookTrackingData = useSelector(selectBaseFacebookTrackingData);
  const memberId = useSelector(getMemberId);
  const currAmount = CurrencyAmount({ amount, currency });

  const t = useTranslations("default");

  const redButtonOn = useFeatureIsOn("red-donation-button");

  const ga4DonateClickTracking = ({ id, text }) => {
    analytics.track(GA4_BUTTON_CLICK, {
      button_id: id,
      button_text: text,
      slug,
      action_page_type: pageType,
    });
  };

  const facebookTracking = () => {
    try {
      logEventToFacebook(FB_EV_INITIATE_CHECKOUT, {
        ...baseFacebookTrackingData,
        value: amount,
        currency: currency,
        ...(memberId && {
          user_id: memberId,
        }),
      });
    } catch (error) {
      // Log error to console if there is an issue with tracking, but do not throw an error
      console.error("Error sending fbq tracking event:", error);
    }
  };

  function getButtonLabel() {
    if (applePay) return "";
    if (recurring) {
      // Check if the donation frequency selected is weekly
      if (weekly) {
        // Calculate the weekly donation amount by dividing the total amount by 4 & round down to the nearest whole number, ensuring that it is an integer
        const weeklyDonationAmount = Math.floor(amount / 4) || 1;
        const curAmnt = CurrencyAmount({
          amount: weeklyDonationAmount, // If the calculated weekly donation amount is 0 (due to a very small total amount), ensure that at least a minimum donation amount of 1 is returned
          currency,
        });
        return t("button_donate_weekly", {
          amount: curAmnt,
        });
      } else
        return t("button_donate_monthly", {
          amount: currAmount,
        });
    } else {
      return t("button_donate_once", {
        amount: currAmount,
      });
    }
  }

  function getWeeklyDonationAmount() {
    if (recurring && weekly) {
      const weeklyDonationAmount = Math.floor(amount / 4) || 1;
      return CurrencyAmount({
        amount: weeklyDonationAmount * 4, // If the calculated weekly donation amount is 0 (due to a very small total amount), ensure that at least a minimum donation amount of 1 is returned
        currency,
      });
    }
  }

  const buttonClasses = (() => {
    if (applePay) {
      return "apple-pay-button apple-pay-button-black";
    } else {
      return `w-full my-2 ${
        redButtonOn ? "bg-red-700" : "bg-purple-primary"
      } h-16 rounded-md text-white focus:outline-none text-md md:text-lg flex items-center justify-center disabled:bg-gray-200 ${classes}`;
    }
  })();

  return (
    <>
      <button
        ref={ref}
        lang={lang}
        className={buttonClasses}
        disabled={disabled || submitting}
        onClick={() => {
          onClick();
          ga4DonateClickTracking({
            id: name,
            text: getButtonLabel(),
          });
          facebookTracking();
        }}
        id={name}
        name={name}
      >
        <span> &nbsp;</span>
        <span className="priFont-extraBold uppercase tracking-tighter md:tracking-normal">
          {getButtonLabel()}
        </span>
      </button>
      {recurring && weekly && (
        <>
          <div className="flex flex-row items-center my-6">
            <div className="w-1/3">
              <img
                className="me-1"
                src="/assets/info.gif"
                alt="checked"
                width={64}
                height={64}
              />
            </div>
            <p className="text-sm">
              <span>{t("weekly_print_alt")}</span>
              <span className="priFont-semiBold">
                {t("followUp_recurrAskAmount", {
                  amount: getWeeklyDonationAmount(),
                })}
              </span>
            </p>
          </div>
        </>
      )}
    </>
  );
});

DonateButton.displayName = "DonateButton";

export default DonateButton;
