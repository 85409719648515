const BodyContent = ({ content }) => {
  return (
    <div
      className="ps-4 pe-4 pb-4 petitionBody"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default BodyContent;
