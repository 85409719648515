import { useDispatch, useSelector } from "react-redux";
import Checkbox from "../../Internal/Checkbox";
import {
  getCoverTransactionCost,
  getDonationAmount,
  setCovertransactionCost,
  setDonationAmount,
} from "../../../reducers/DonationReducer";
import { getPageSlug, getPageType } from "../../../reducers/PageReducer";
import analytics from "../../../lib/analytics";
import { GA4_COVER_TRANSACTION_COST } from "../../TrackingScripts/helpers";
import { useTranslations } from "next-intl";

export const TRANSACTION_COST_MULTIPLIER = 1.05;

const CoverTransactionCosts = (props) => {
  const dispatch = useDispatch();
  const coverTransactionCost = useSelector(getCoverTransactionCost);
  const amount = useSelector(getDonationAmount);
  const slug = useSelector(getPageSlug);
  const pageType = useSelector(getPageType);

  const t = useTranslations("donationForm");

  function handleCheckboxUpdate(value) {
    const multipliedAmount = value
      ? amount * TRANSACTION_COST_MULTIPLIER
      : amount / TRANSACTION_COST_MULTIPLIER;
    const chargableAmount = parseFloat(multipliedAmount.toFixed(2));

    console.log("chargableAmount --", chargableAmount);

    dispatch(setDonationAmount(chargableAmount));
    dispatch(setCovertransactionCost(value));

    if (value) {
      analytics.track(GA4_COVER_TRANSACTION_COST, {
        checked: value,
        slug,
        action_page_type: pageType,
      });
    }
  }
  return (
    <div
      className="mb-6"
      id="cover-transaction-cost"
      data-testid="cover-transaction-cost"
    >
      <Checkbox
        name="Checkbox_Cover_Transaction_Cost"
        id="Checkbox_Cover_Transaction_Cost"
        dataTestId="coverTransactionCost"
        classes="Checkbox Cover_Transaction_Cost"
        placeholder={t("cover_transaction_costs_label")}
        checked={coverTransactionCost}
        value={coverTransactionCost}
        onChange={(e) => {
          handleCheckboxUpdate(e.currentTarget.checked);
        }}
      />
      <span className="text-xs">
        {t.rich("cover_transaction_costs_message", {
          b: (children) => <b>{children}</b>,
        })}
      </span>
    </div>
  );
};

export default CoverTransactionCosts;
