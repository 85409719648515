import { currencySymbols } from "@/utils/constants";

type DonationBandsType = {
  [curreny: string]: number[];
};

export const donationBands: DonationBandsType = {
  USD: [1, 2, 3],
  GBP: [1, 2, 34],
  EUR: [1, 2, 3],
  AUD: [1, 2, 3],
  CAD: [1, 2, 3],
  CHF: [1, 2, 3],
  DKK: [1, 2, 3],
};

export const supportedCurrencies: string[] = Object.keys(currencySymbols);

export const formatCurrency = (value: string, currency: string): string => {
  const symbol = currencySymbols[currency] || "";
  if (currency === "CHF") return `${value} ${symbol}`;
  else return currency === "EUR" ? `${value}${symbol}` : `${symbol}${value}`;
};

export const isCurrencySymbolBeforeValue = (currency: string): boolean => {
  if (currency === "CHF" || currency === "EUR") return false;
  return true;
};

export const makeCancelable = <T>(promise: Promise<T>) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then((val) =>
      hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)
    );
    promise.catch((error) =>
      hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export const importRetry = async (
  importFn: () => Promise<any>,
  retries = 2,
  interval = 1000
) => {
  try {
    return await importFn();
  } catch (error) {
    if (retries) {
      await wait(interval);
      return importRetry(importFn, retries - 1, interval);
    } else {
      throw new Error(error);
    }
  }
};

function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const transactionalCost = (amount: number, tip: number): number => {
  return Math.round(((amount * tip) / 100) * 100) / 100;
};
